import { types, flow, getEnv, getRoot } from 'mobx-state-tree';

import { is } from '../utils/global';
import { Brand } from '../models/Brand';
import { ROUTES } from '../constants';
import { ShortList } from '../models/ShortList';

const BrandProfile = types
  .model('BrandProfile', {
    loading: false,
    user: types.maybeNull(types.reference(Brand)),
    state: types.frozen(),
    errors: types.frozen(),
    favourite_lists: types.optional(types.array(ShortList), []),
    loadingLists: true,
  })
  .actions((self) => ({
    fetchProfile: flow(function* (profileId) {
      const { http } = getEnv(self);
      const { auth } = getRoot(self);

      self.loading = true;
      self.state = '';

      const response = yield http.get(`/brands/${profileId}/settings`);

      if (is(response.status, 200)) {
        const { brand } = yield response.json();

        self.putProfileData(brand);
        self.state = 'success';
        self.loading = false;
      }

      if (is(response.status, 404)) {
        const { error } = yield response.json();

        self.errors = error;
        self.state = 'not found';
        self.loading = false;
      }

      if (is(response.status, 401)) {
        self.loading = false;
        auth.setUnauthorized();
      }
    }),

    updateProfile: flow(function* (data, onSuccess) {
      const { http } = getEnv(self);
      const { auth, snackBars } = getRoot(self);

      self.loading = true;
      self.state = '';

      const profileId = self.user.id;
      const body = {
        brand: data,
      };

      const response = yield http.put(`/brands/${profileId}`, body);

      if (is(response.status, 200)) {
        const { brand } = yield response.json();

        self.putProfileData(brand);

        auth.getSession();

        self.state = 'success';
        self.loading = false;

        snackBars.addNotification({
          message: 'Successfully updated',
          options: {
            variant: 'success',
          },
        });
      }

      if (is(response.status, 401)) {
        auth.setUnauthorized();
      }

      if (is(response.status, 404)) {
        const { error } = yield response.json();

        self.errors = error;
        self.state = 'not found';
      }

      if (is(response.status, 422)) {
        const { error } = yield response.json();
        self.state = 'error';

        snackBars.addNotification({
          message: error,
          options: {
            variant: 'error',
          },
        });
      }

      self.loading = false;
    }),

    putProfileData(data) {
      const { brands_db } = getRoot(self);
      self.user = data.id;
      self.state = 'success';
      self.errors = '';
      self.loading = false;

      return brands_db.put(data);
    },

    deleteProfile: flow(function* (profileId) {
      const { http } = getEnv(self);
      const { auth } = getRoot(self);

      const response = yield http.delete(`/brands/${profileId}`);

      if (is(response.status, 204)) {
        localStorage.removeItem('token');
        auth.setUnauthorized();
      }
    }),

    approveRequest: flow(function* (actionId) {
      const { http } = getEnv(self);
      const { auth, notifications } = getRoot(self);

      const response = yield http.put(`/brand_approvals/${actionId}/approve`);

      if (is(response.status, 200)) {
        notifications.fetchNotifications();
      }

      if (is(response.status, 401)) {
        auth.setUnauthorized();
      }

      if (is(response.status, 404)) {
      }
    }),

    declineRequest: flow(function* (actionId) {
      const { http } = getEnv(self);
      const { auth, notifications } = getRoot(self);

      const response = yield http.put(`/brand_approvals/${actionId}/decline`);

      if (is(response.status, 200)) {
        notifications.fetchNotifications();
      }

      if (is(response.status, 401)) {
        auth.setUnauthorized();
      }

      if (is(response.status, 404)) {
      }
    }),

    leaveReview: flow(function* (body) {
      const { http } = getEnv(self);
      const { auth, modal, snackBars } = getRoot(self);

      const response = yield http.post(`/reviews`, body);

      if (is(response.status, 201)) {
        modal.close();

        snackBars.addNotification({
          message: 'Successfully added',
          options: {
            variant: 'success',
          },
        });
      }

      if (is(response.status, 401)) {
        auth.setUnauthorized();
      }

      if (is(response.status, 404)) {
      }

      if (is(response.status, 422)) {
        const { error } = yield response.json();

        snackBars.addNotification({
          message: error,
          options: {
            variant: 'error',
          },
        });
      }
    }),

    confirmReview: flow(function* (reviewId, onSuccess) {
      const { http } = getEnv(self);
      const { auth, modal, notifications, snackBars } = getRoot(self);

      const response = yield http.put(`/reviews/${reviewId}/confirm`);

      if (is(response.status, 200)) {
        if (modal.isOpen) modal.close();

        notifications.clearPages();
        notifications.fetchNotifications();
        if (onSuccess) onSuccess();

        snackBars.addNotification({
          message: 'Successfully added',
          options: {
            variant: 'success',
          },
        });
      }

      if (is(response.status, 401)) {
        auth.setUnauthorized();
      }

      if (is(response.status, 404)) {
      }
    }),

    editReview: flow(function* (reviewId, body, onSuccess) {
      const { http } = getEnv(self);
      const { auth, modal, snackBars } = getRoot(self);

      const response = yield http.put(`/reviews/${reviewId}`, body);

      if (is(response.status, 200)) {
        const { review } = yield response.json();
        onSuccess(review);
        modal.close();

        snackBars.addNotification({
          message: 'Successfully edited',
          options: {
            variant: 'success',
          },
        });
      }

      if (is(response.status, 401)) {
        auth.setUnauthorized();
      }

      if (is(response.status, 404)) {
      }

      if (is(response.status, 422)) {
        const { error } = yield response.json();

        snackBars.addNotification({
          message: error,
          options: {
            variant: 'error',
          },
        });
      }
    }),

    deleteReview: flow(function* (reviewId, onSuccess) {
      const { http } = getEnv(self);
      const { auth, modal, notifications, snackBars } = getRoot(self);

      const response = yield http.delete(`/reviews/${reviewId}`);

      if (is(response.status, 204)) {
        if (modal.isOpen) modal.close();

        notifications.clearPages();
        notifications.fetchNotifications();

        if (onSuccess) onSuccess();

        snackBars.addNotification({
          message: 'Successfully deleted',
          options: {
            variant: 'success',
          },
        });
      }

      if (is(response.status, 401)) {
        auth.setUnauthorized();
      }

      if (is(response.status, 404)) {
      }
    }),

    putListsData(data) {
      const { lists_db } = getRoot(self);
      return lists_db.put(data);
    },

    getShortLists: flow(function* () {
      const { http } = getEnv(self);
      const { auth, snackBars } = getRoot(self);

      self.loadingLists = true;

      const response = yield http.get(`/favourite_lists`);

      if (is(response.status, 200)) {
        const { favourite_lists } = yield response.json();

        self.favourite_lists = favourite_lists;
        favourite_lists.forEach((list) => self.putListsData(list));
      }

      if (is(response.status, 404)) {
        const { error } = yield response.json();

        snackBars.addNotification({
          message: error,
          options: {
            variant: 'error',
          },
        });
      }

      if (is(response.status, 401)) {
        auth.setUnauthorized();
      }
      self.loadingLists = false;
    }),

    createShortList: flow(function* (body, onSuccess, onError) {
      const { http } = getEnv(self);
      const { auth, snackBars } = getRoot(self);

      self.loading = true;

      const response = yield http.post(`/favourite_lists`, body);

      if (is(response.status, 201)) {
        const { favourite_list } = yield response.json();

        if (onSuccess) onSuccess(favourite_list);
        self.getShortLists();

        snackBars.addNotification({
          message: 'Successfully created',
          options: {
            variant: 'success',
          },
        });
      }

      if (is(response.status, 401)) {
        auth.setUnauthorized();
      }

      if (is(response.status, 422)) {
        const { error } = yield response.json();

        snackBars.addNotification({
          message: error,
          options: {
            variant: 'error',
          },
        });

        if (onError) onError();
      }
      self.loading = false;
    }),

    editList: flow(function* (body, id, onSuccess) {
      const { http, history } = getEnv(self);
      const { auth, snackBars, modal } = getRoot(self);

      const response = yield http.put(`/favourite_lists/${id}`, body);

      if (is(response.status, 200)) {
        const { favourite_list } = yield response.json();
        onSuccess(favourite_list);
        modal.close();

        self.getShortLists();

        snackBars.addNotification({
          message: 'Successfully edited',
          options: {
            variant: 'success',
          },
        });
      }

      if (is(response.status, 401)) {
        auth.setUnauthorized();
      }

      if (is(response.status, 404)) {
        history.push(ROUTES.shortLists);

        snackBars.addNotification({
          message: "Couldn't find list",
          options: {
            variant: 'error',
          },
        });
      }

      if (is(response.status, 422)) {
        const { error } = yield response.json();

        snackBars.addNotification({
          message: error,
          options: {
            variant: 'error',
          },
        });
      }
    }),

    deleteList: flow(function* (id) {
      const { http, history } = getEnv(self);
      const { auth, snackBars } = getRoot(self);

      const response = yield http.delete(`/favourite_lists/${id}`);

      if (is(response.status, 204)) {
        history.push(ROUTES.shortLists);

        snackBars.addNotification({
          message: 'Successfully deleted',
          options: {
            variant: 'warning',
          },
        });
      }

      if (is(response.status, 401)) {
        auth.setUnauthorized();
      }

      if (is(response.status, 404)) {
        history.push(ROUTES.shortLists);

        snackBars.addNotification({
          message: "Couldn't find list",
          options: {
            variant: 'error',
          },
        });
      }

      if (is(response.status, 422)) {
        const { error } = yield response.json();

        snackBars.addNotification({
          message: error,
          options: {
            variant: 'error',
          },
        });
      }
    }),

    addItemToList: flow(function* (body, onSuccess) {
      const { http } = getEnv(self);
      const { auth, snackBars, modal } = getRoot(self);

      const response = yield http.post(`/favourite_list_items`, body);

      if (is(response.status, 201)) {
        modal.close();

        snackBars.addNotification({
          message: 'Successfully added',
          options: {
            variant: 'success',
          },
        });

        if (onSuccess) onSuccess();
      }

      if (is(response.status, 401)) {
        auth.setUnauthorized();
      }

      if (is(response.status, 422)) {
        const { error } = yield response.json();

        snackBars.addNotification({
          message: error,
          options: {
            variant: 'error',
          },
        });
      }
    }),
  }));

export { BrandProfile };
