import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
import { Router as BrowserRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import './ui/index.scss';
import { createStores } from './stores/bootstrap';
import App from './App';
import { Http } from './utils/http';
import Notifier from './components/snackBars';
import SnackbarProviderWrapper from './components/snackBars/SnackBar';
import AuthWrapper from './layouts/AuthWrapper';
import { SENTRY_DSN } from './constants'

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const http = new Http();
const history = createBrowserHistory();
const stores = createStores({ http, history });

window.STORES = stores;

const app = (
  <Provider {...stores}>
    <BrowserRouter history={history}>
      <SnackbarProviderWrapper>
        <AuthWrapper>
          <App stores={stores} />
          <Notifier />
        </AuthWrapper>
      </SnackbarProviderWrapper>
    </BrowserRouter>
  </Provider>
);

ReactDOM.render(app, document.getElementById('root'));
