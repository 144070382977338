import { makeQueryFromParams } from './global';
import { REACT_APP_API_URL } from '../constants';

class Http {
  async _request(method, endpoint, body, options) {
    const token = localStorage.getItem('token');
    const url = this.makeUrl(endpoint, options);
    const headers = new Headers({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token ? `Bearer ${token}` : '',
    });

    const request = {
      method,
      headers,
    };

    if (options) request.signal = options.signal;

    if (body) {
      if (body instanceof FormData) {
        request.body = body;
        headers.delete('Content-Type');
      } else request.body = JSON.stringify(body);
    }

    const response = await fetch(url, request);

    return response;
  }

  get(endpoint, body, options) {
    return this._request('GET', endpoint, body, options);
  }

  post(endpoint, body, options) {
    return this._request('POST', endpoint, body, options);
  }

  delete(endpoint, body, options) {
    return this._request('DELETE', endpoint, body, options);
  }

  put(endpoint, body, options) {
    return this._request('PUT', endpoint, body, options);
  }

  patch(endpoint, body, options) {
    return this._request('PATCH', endpoint, body, options);
  }

  params(options: HttpOptions) {
    let params = '';

    if (options.params) {
      params = `?${makeQueryFromParams(options.params)}`;
      delete options.params;
    }

    return params;
  }

  makeUrl(endpoint, options = {}) {
    return `${REACT_APP_API_URL}${endpoint}${this.params(options)}`;
  }
}

export { Http };
