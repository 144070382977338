import React, { useRef, useState, useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import Types from 'prop-types';

const CategoryLink = ({ category, search, onCloseMenu }) => {
  function setCategory(event) {
    search.setIsRedirected(true);
    search.setSearchByCategory([category.id]);
    onCloseMenu(event);
  }

  return (
    <li className="icon-arrow-right" onClick={setCategory}>
      <span to={'/consultants/search'}>{category.name}</span>
    </li>
  );
};

CategoryLink.propTypes = {
  search: Types.object.isRequired,
  category: Types.object.isRequired,
  onCloseMenu: Types.func,
};

const CategoryDropdown = ({ type, global, search, onCloseMenu }) => {
  const containerRef = useRef(null);
  const [isOpen, setOpenedDropdown] = useState(false);

  useEffect(() => {
    document.addEventListener('click', closeDropdownOnClickOutside);

    return () => {
      document.removeEventListener('click', closeDropdownOnClickOutside);
    };
  });

  function toggleOpenDropdown(event) {
    setOpenedDropdown(!isOpen);
  }

  function closeDropdownOnClickOutside(event) {
    if (containerRef && containerRef.current) {
      if (!containerRef.current.contains(event.target)) {
        setOpenedDropdown(false);
      }
    }
  }

  return (
    <div onClick={toggleOpenDropdown} ref={containerRef}>
      <span>Categories</span>
      <span className={`icon-arrow${isOpen ? ' rotate' : ''}`} />
      <ul className={`${type}__nav-subnav${isOpen ? ' open' : ''}`}>
        {global.categories.map((category) => (
          <CategoryLink
            key={category.id}
            category={category}
            search={search}
            onCloseMenu={onCloseMenu}
          />
        ))}
      </ul>
    </div>
  );
};

CategoryDropdown.propTypes = {
  global: Types.object.isRequired,
  search: Types.object.isRequired,
  type: Types.string,
  onCloseMenu: Types.func,
};

export default inject('global', 'search')(observer(CategoryDropdown));
