import { types as t } from 'mobx-state-tree';

import { Consultant } from '../models/Consultant';

const ConsultantsDB = t
  .model('ConsultantsDB', {
    consultantList: t.optional(t.map(Consultant), {}),
  })
  .actions((self) => ({
    put(consultant: typeof Consultant) {
      let newConsultant = consultant;
      const oldConsultant = self.get(consultant.id);

      if (oldConsultant) {
        newConsultant = Object.assign(oldConsultant, newConsultant);
      }
      return self.consultantList.put(newConsultant);
    },

    get(id: number) {
      return self.consultantList.get(id.toString());
    },

    byIds(ids) {
      return ids.map(self.get);
    },
  }));

export { ConsultantsDB };
