import { types as t } from 'mobx-state-tree';

import { Vendor } from '../models/Vendor';

const VendorsDB = t
  .model('VendorsDB', {
    vendorList: t.optional(t.map(Vendor), {}),
  })
  .actions((self) => ({
    put(vendor: typeof Vendor) {
      let newVendor = vendor;
      const oldVendor = self.get(vendor.id);

      if (oldVendor) {
        newVendor = Object.assign(oldVendor, newVendor);
      }
      return self.vendorList.put(newVendor);
    },

    get(id: number) {
      return self.vendorList.get(id.toString());
    },

    byIds(ids) {
      return ids.map(self.get);
    },
  }));

export { VendorsDB };
