import { types as t } from 'mobx-state-tree';

import { Developer } from '../models/Developer';

const DevelopersDB = t
  .model('DevelopersDB', {
    me: t.maybeNull(t.reference(Developer)),
    developersList: t.optional(t.map(Developer), {}),
  })
  .actions((self) => ({
    setMe(me) {
      self.me = me.id;
    },

    put(developer: typeof Developer) {
      let newDeveloper = developer;
      const oldDeveloper = self.get(developer.id);

      if (oldDeveloper) {
        newDeveloper = Object.assign(oldDeveloper, newDeveloper);
      }
      return self.developersList.put(newDeveloper);
    },

    get(id: number) {
      return self.developersList.get(id.toString());
    },

    byIds(ids) {
      return ids.map(self.get);
    },
  }));

export { DevelopersDB };
