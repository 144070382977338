import React, { useState, useRef, useEffect } from 'react';
import Types from 'prop-types';
import { observer, inject } from 'mobx-react';
import { isEmpty } from 'ramda';

import LazyLoading from '../../../components/common/LazyLoading';
import { is } from '../../../utils/global';
import Notification from './Notification';

const EmptyNotificationList = () => (
  <div className="p-16">
    <p className="text-center grey-box">you have no notification yet</p>
  </div>
);

const NotificationsDropdown = ({
  notifications,
  auth,
  brand_profile,
  developer_profile,
}) => {
  const containerRef = useRef(null);
  const [isOpen, setOpenDropdown] = useState(false);
  const [notificationsList, setNotifications] = useState([]);
  const [newNotificationsList, setNewNotifications] = useState([]);

  useEffect(() => {
    document.addEventListener('click', closeDropdownOnClickOutside);

    return () => {
      document.removeEventListener('click', closeDropdownOnClickOutside);
    };
  });

  useEffect(() => {
    if (!isEmpty(notifications.list && !notifications.loading)) {
      let newNotifications = [];
      let earlierNotifications = [];

      notifications.list.forEach((notification) => {
        if (is(notification.is_new, true)) {
          newNotifications.push(notification);
        } else earlierNotifications.push(notification);
      });

      setNotifications(earlierNotifications);
      setNewNotifications(newNotifications);
    }
  }, [notifications.list, notifications.loading]);

  function closeDropdownOnClickOutside(event) {
    if (containerRef && containerRef.current) {
      if (!containerRef.current.contains(event.target)) {
        setOpenDropdown(false);
        if (!isEmpty(notifications.list))
          notifications.clearNotificationsList();
      }
    }
  }

  function toggleOpenDropdown(event) {
    if (!isOpen) {
      notifications.fetchNotifications();
    } else notifications.clearNotificationsList();
    setOpenDropdown(!isOpen);
  }

  const myProfile = is(auth.me.role, 'Brand')
    ? brand_profile
    : developer_profile;

  return (
    <div className="notifications-dropdown" ref={containerRef}>
      <button
        type="button"
        title="Notifications"
        className={`btn-minimal icon-notification ${
          auth.me.has_new_notifications ? 'new' : ''
        }`}
        onClick={toggleOpenDropdown}
      />
      <div className={`notifications-dropdown-menu${isOpen ? ' open' : ''}`}>
        <h1>Notifications</h1>
        <button
          type="button"
          className="btn-minimal icon-close"
          title="Close"
          onClick={toggleOpenDropdown}
        />
        {isEmpty(notifications.list) && !notifications.loading && (
          <EmptyNotificationList />
        )}

        <ul>
          {!isEmpty(newNotificationsList) && (
            <>
              <h3>New</h3>
              {newNotificationsList.map((notification) => (
                <Notification
                  key={notification.id}
                  notification={notification}
                  toggleOpenDropdown={toggleOpenDropdown}
                  makeReadNotification={notifications.makeReadNotification}
                  me={auth.me}
                  myProfile={myProfile}
                />
              ))}
            </>
          )}
          {!isEmpty(notificationsList) && (
            <>
              <h3>Earlier</h3>
              {notificationsList.map((notification) => (
                <Notification
                  key={notification.id}
                  notification={notification}
                  toggleOpenDropdown={toggleOpenDropdown}
                  makeReadNotification={notifications.makeReadNotification}
                  me={auth.me}
                  myProfile={myProfile}
                />
              ))}
            </>
          )}

          {!notifications.loading &&
            notifications.pagination.current_page <
              notifications.pagination.total_pages && (
              <LazyLoading onChange={notifications.fetchNotifications} />
            )}
          {notifications.loading && (
            <div className="loader-wrapper">
              <div className="loader" />
            </div>
          )}
        </ul>
      </div>
    </div>
  );
};

NotificationsDropdown.propTypes = {
  notifications: Types.object.isRequired,
  brand_profile: Types.object,
  developer_profile: Types.object,
  auth: Types.object,
};

export default inject(
  'notifications',
  'brand_profile',
  'developer_profile',
  'auth'
)(observer(NotificationsDropdown));
