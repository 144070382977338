import { types, flow, getEnv, getRoot } from 'mobx-state-tree';
import { reaction } from 'mobx';

import { is } from '../utils/global';
import { ROUTES } from '../constants';

const State = types.model('State', {
  state: types.frozen(),
  errors: types.frozen(),
});

const Auth = types
  .model('Auth', {
    login: types.optional(State, { state: 'pending' }),
    registration: types.optional(State, {}),
    reset: types.optional(State, {}),
    registration_email: types.maybeNull(types.string),
    email_verification: types.optional(State, {}),
    me: types.optional(
      types.model('Me', {
        id: types.frozen(),
        name: types.frozen(),
        role: types.frozen(),
        logo: types.frozen(),
        email: types.frozen(),
        has_new_notifications: types.frozen(),
      }),
      {}
    ),
  })
  .actions((self) => ({
    afterAttach() {
      if (self.isLogin) self.init();

      reaction(
        () => self.isLogin,
        (isLogin) => isLogin && self.init()
      );
    },

    init() {
      //
    },

    doRegistration: flow(function* (body, type, onSuccess) {
      const { http } = getEnv(self);
      const { snackBars } = getRoot(self);

      self.registration.errors = '';
      self.registration.state = 'pending';

      const registrationLink = self.getRegistrationLink(type);

      const response = yield http.post(registrationLink, body);

      if (is(response.status, 201)) {
        self.registration.state = 'success';

        onSuccess();
      }

      if (is(response.status, 422)) {
        const { error } = yield response.json();
        self.registration.state = 'error';

        snackBars.addNotification({
          message: error,
          options: {
            variant: 'error',
          },
        });
      }
    }),

    getRegistrationLink(type) {
      if (is(type, 'consultant')) return '/design_agencies';
      if (is(type, 'vendor')) return '/vendors';
      if (is(type, 'developer')) return '/developers';
      if (is(type, 'brand')) return '/brands';
    },

    saveRegistrationEmail(email: string) {
      self.registration_email = email;
    },

    resendEmail: flow(function* (onSuccess) {
      const { http } = getEnv(self);
      const { snackBars } = getRoot(self);

      self.email_verification.state = 'pending';

      const body = {
        user: {
          email: self.registration_email,
        },
      };

      const response = yield http.post('/users/confirmation', body);

      if (is(response.status, 200)) {
        self.email_verification.state = 'success';
        self.email_verification.errors = '';

        snackBars.addNotification({
          message: 'Verification instruction has been resent',
          options: {
            variant: 'success',
          },
        });

        onSuccess(true);
      }

      if (is(response.status, 422)) {
        const { error } = yield response.json();
        self.email_verification.state = 'error';
        self.email_verification.errors = error;

        snackBars.addNotification({
          message: error,
          options: {
            variant: 'error',
          },
        });
      }
    }),

    getSession: flow(function* () {
      const { http } = getEnv(self);
      self.login.errors = '';

      const response = yield http.get('/accounts');

      if (is(response.status, 200)) {
        const { account } = yield response.json();

        self.setMe(account);
        self.login.state = 'success';
      }

      if (is(response.status, 401)) {
        self.setUnauthorized();
      }
    }),

    doLogin: flow(function* (body) {
      const { http, history } = getEnv(self);
      const { snackBars } = getRoot(self);

      localStorage.removeItem('token');
      self.login.errors = '';
      self.login.state = 'loading';

      const response = yield http.post('/users/sign_in', body);

      if (is(response.status, 200)) {
        const authorization = response.headers.get('Authorization');
        const token = authorization.slice(7);
        localStorage.setItem('token', token);
        localStorage.setItem('login_state', 'success');

        yield self.getSession();
        self.login.state = 'success';

        return history.push(ROUTES.settings);
      }

      if (is(response.status, 401)) {
        const { error } = yield response.json();
        self.login.errors = error;
        self.login.state = 'unauthorized';

        snackBars.addNotification({
          message: error,
          options: {
            variant: 'error',
          },
        });

        if (
          is(error, 'You have to confirm your email address before continuing.')
        ) {
          self.registration_email = body.api_v1_user.email;

          return history.push(ROUTES.resendEmailVerification);
        }
      }
    }),

    setRegistrationEmail(email) {
      self.registration_email = email;
    },

    doLogout: flow(function* () {
      const { http } = getEnv(self);

      const response = yield http.delete('/users/sign_out');
      if (response.ok || is(response.status, 401)) {
        self.setUnauthorized();
      }
    }),

    clearAccountData() {
      localStorage.removeItem('token');

      self.me.id = undefined;
      self.me.role = undefined;
      self.me.name = undefined;
      self.me.email = undefined;
      self.me.logo = undefined;
    },

    setUnauthorized() {
      const { notifications } = getRoot(self);

      self.login.state = 'unauthorized';
      localStorage.removeItem('login_state');

      self.clearAccountData();
      notifications.clearNotificationsList();
    },

    doCreatePassword: flow(function* (body, token) {
      const { http, history } = getEnv(self);
      const { snackBars } = getRoot(self);

      localStorage.setItem('token', token);
      self.reset.state = 'pending';

      const response = yield http.put('/users/password', body);

      if (is(response.status, 200)) {
        const { message } = yield response.json();

        snackBars.addNotification({
          message: message,
          options: {
            variant: 'success',
          },
        });

        setTimeout(() => {
          history.push(ROUTES.login);
        }, 0);
        self.reset.state = '';
      }

      if (is(response.status, 400)) {
        const { errors } = yield response.json();

        snackBars.addNotification({
          message: errors,
          options: {
            variant: 'error',
          },
        });

        self.reset.state = 'error';
      }
      self.reset.state = '';
    }),

    doResetPassword: flow(function* (body, onSuccess) {
      const { http } = getEnv(self);
      self.reset.state = 'pending';

      const response = yield http.post('/users/password', body);

      if (is(response.status, 201)) {
        onSuccess();
        self.reset.state = 'success';
      }
      self.reset.state = '';
    }),

    doSetNewPassword: flow(function* (body, onSuccess) {
      const { http } = getEnv(self);
      const { snackBars } = getRoot(self);

      self.reset.state = 'pending';

      const response = yield http.put('/users', body);

      if (is(response.status, 200)) {
        const { message } = yield response.json();

        snackBars.addNotification({
          message: message,
          options: {
            variant: 'success',
          },
        });

        onSuccess();
        self.reset.state = 'success';
      }

      if (is(response.status, 400)) {
        const { errors } = yield response.json();

        snackBars.addNotification({
          message: errors,
          options: {
            variant: 'error',
          },
        });
      }

      self.reset.state = '';
    }),

    setMe(account) {
      self.me = account;
    },

    createStripeSession: flow(function* (priceId, customerEmail) {
      const { http } = getEnv(self);
      const { snackBars } = getRoot(self);

      self.reset.state = 'pending';

      const response = yield http.post(
        `/stripe/create_checkout_session?price_id=${priceId}&customer_email=${customerEmail}`
      );

      if (is(response.status, 303)) {
        const { url } = yield response.json();
        self.reset.state = 'success';
        window.location.href = url;
      }

      if (is(response.status, 422)) {
        const { error } = yield response.json();

        snackBars.addNotification({
          message: error,
          options: {
            variant: 'error',
          },
        });
      }
      self.reset.state = '';
    }),
  }))
  .views((self) => ({
    get isLogin() {
      const loginState = localStorage.getItem('login_state');
      return is(self.login.state, 'success') || is(loginState, 'success');
    },
  }));

export { Auth };
