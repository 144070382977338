import { types, flow, getEnv, getRoot } from 'mobx-state-tree';
import { isEmpty } from 'ramda';

import { is } from '../utils/global';
import { Pagination } from './Global';

const Notifications = types
  .model('Notifications', {
    loading: true,
    list: types.optional(types.array(types.frozen()), []),
    pagination: Pagination,
  })
  .actions((self) => ({
    fetchNotifications: flow(function* () {
      const { http } = getEnv(self);
      const { auth } = getRoot(self);

      const params = { page: self.pagination.current_page + 1 };
      self.loading = true;

      const response = yield http.get(`/notifications`, null, { params });

      if (is(response.status, 200)) {
        const { notifications, pagination } = yield response.json();

        if (!isEmpty(notifications)) {
          notifications.forEach((notification) => {
            const existingNotification = self.list.find(
              ({ id }) => id === notification.id
            );

            if (existingNotification) return;

            self.list.push(notification);
          });

          self.pagination = pagination;
        }
      }

      if (is(response.status, 401)) {
        auth.setUnauthorized();
      }

      self.loading = false;
    }),

    makeReadNotification: flow(function* (notificationId) {
      const { http } = getEnv(self);
      const { auth } = getRoot(self);

      const response = yield http.put(
        `/notifications/${notificationId}/make_old`
      );

      if (is(response.status, 204)) {
        auth.getSession();
      }

      if (is(response.status, 401)) {
        auth.setUnauthorized();
      }
    }),

    clearPages() {
      self.pagination.current_page = 0;
      self.pagination.total_pages = 0;
      self.pagination.total_records = 0;
    },

    clearNotificationsList() {
      self.list = [];
      self.clearPages();
    },
  }));

export { Notifications };
