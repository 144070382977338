import { types as t, flow, getEnv, getRoot } from 'mobx-state-tree';

import { is } from '../utils/global';

const ShortList = t
  .model('ShortList', {
    description: t.string,
    id: t.identifierNumber,
    name: t.string,
    list_items: t.maybeNull(t.array(t.frozen()), []),
    items_count: 0,
  })
  .actions((self) => ({
    editItem: flow(function* (body, id, onSuccess) {
      const { http } = getEnv(self);
      const { auth, snackBars, modal } = getRoot(self);

      const response = yield http.put(`/favourite_list_items/${id}`, body);

      if (is(response.status, 200)) {
        const { favourite_list_item } = yield response.json();
        onSuccess(favourite_list_item);
        modal.close();

        const existingList = self.list_items.find(
          ({ id }) => id === favourite_list_item.id
        );
        if (existingList) {
          const index = self.list_items.indexOf(existingList);
          if (index !== -1) {
            self.list_items[index] = favourite_list_item;
          }
        }

        snackBars.addNotification({
          message: 'Successfully edited',
          options: {
            variant: 'success',
          },
        });
      }

      if (is(response.status, 401)) {
        modal.close();
        auth.setUnauthorized();
      }

      if (is(response.status, 404)) {
        snackBars.addNotification({
          message: "Couldn't find design agency",
          options: {
            variant: 'error',
          },
        });
      }

      if (is(response.status, 422)) {
        const { error } = yield response.json();

        snackBars.addNotification({
          message: error,
          options: {
            variant: 'error',
          },
        });
      }
    }),

    deleteItem: flow(function* (id, onSuccess) {
      const { http } = getEnv(self);
      const { auth, modal, snackBars } = getRoot(self);

      const response = yield http.delete(`/favourite_list_items/${id}`);

      if (is(response.status, 204)) {
        onSuccess(id);
        modal.close();

        snackBars.addNotification({
          message: 'Successfully deleted',
          options: {
            variant: 'success',
          },
        });
      }

      if (is(response.status, 401)) {
        modal.close();
        auth.setUnauthorized();
      }

      if (is(response.status, 404)) {
      }
    }),
  }));

export { ShortList };
