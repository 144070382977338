import React, { useState, useEffect } from 'react';
import { NavLink, Link, withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';

import { ROUTES } from '../../constants';
import CategoryDropdown from '../../components/common/CategoryDropdown';
import { is, stopPropagation } from '../../utils/global';
import { Media } from '../../components/common/Media';
import UserDropdown from './UserDropdown';
import NotificationsDropdown from './notificationsDropdown';
import MobileNavigation from './MobileNavigation';

const Header = ({ auth, modal, location }) => {
  const [isOpenMenu, setOpenMenu] = useState(false);
  const [isLogin, setIsLogin] = useState(false);

  useEffect(() => {
    if (auth.isLogin) {
      setIsLogin(true);
    } else setIsLogin(false);
  }, [auth.isLogin]);

  function toggleOpenMenu(event) {
    stopPropagation(event);
    setOpenMenu(!isOpenMenu);
  }

  function onCloseMenu(event) {
    if (isOpenMenu) {
      setOpenMenu(false);
    }
  }

  function openModal() {
    modal.open('SIGN_UP_INVITATION', {});
  }

  const isSearchPage = location.pathname.includes(`/consultants/search`);

  return (
    <header className="header" role="banner">
      <div className="header__wrapper">
        <div className="header__logo" onClick={onCloseMenu}>
          <NavLink to={ROUTES.root}>
            <img src={require(`../../assets/logo.svg`).default} alt="VIPRFP" />
          </NavLink>
        </div>
        <Media query="(max-width: 1024px)">
          {(match) =>
            match ? (
              <MobileNavigation
                openModal={openModal}
                toggleOpenMenu={toggleOpenMenu}
                isOpenMenu={isOpenMenu}
                setOpenMenu={setOpenMenu}
                setIsLogin={setIsLogin}
                isLogin={isLogin}
              />
            ) : (
              <div className="header__nav-wrapper">
                <nav>
                  <ul className="header__nav">
                    {auth.isLogin ? (
                      <>
                        <li>
                          <NavLink to={ROUTES.industryLeaders}>
                            Industry Leaders
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to={ROUTES.brandsDevelopers}>
                            Brands & Developers
                          </NavLink>
                        </li>
                        {(is(auth.me.role, 'Brand') ||
                          is(auth.me.role, 'Developer')) && (
                          <li className={`tab${isSearchPage ? ' active' : ''}`}>
                            <CategoryDropdown
                              type={'header'}
                              onCloseMenu={() => {}}
                            />
                          </li>
                        )}
                      </>
                    ) : (
                      <>
                        <li onClick={openModal}>
                          <span>Industry Leaders</span>
                        </li>
                        <li onClick={openModal}>
                          <span> Brands & Developers</span>
                        </li>
                        <li onClick={openModal}>
                          <span>Categories</span>
                        </li>
                      </>
                    )}
                    <li>
                      <NavLink to={ROUTES.confidentiality}>
                        Confidentiality
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={ROUTES.howItWorks}>How It Works</NavLink>
                    </li>
                  </ul>
                  <div className="header__login">
                    {isLogin ? (
                      <>
                        {(is(auth.me.role, 'Brand') ||
                          is(auth.me.role, 'Developer')) && (
                          <Link
                            className="icon-icon-bookmark"
                            to={ROUTES.shortLists}></Link>
                        )}
                        <NotificationsDropdown />
                        <UserDropdown setIsLogin={setIsLogin} />
                      </>
                    ) : (
                      <>
                        <NavLink className="login" to={ROUTES.login}>
                          Log in
                        </NavLink>
                        <NavLink
                          className="lnk-as-btn-primary"
                          to={ROUTES.partnerWithUs}>
                          Partner With Us
                        </NavLink>
                      </>
                    )}
                  </div>
                </nav>
              </div>
            )
          }
        </Media>
      </div>
    </header>
  );
};

export default inject('auth', 'modal')(withRouter(observer(Header)));
