import React from 'react';

const Loader = () => (
  <div className="page-loader-wrapper">
    <div className="loader" />
    <p>Loading, one moment please...</p>
  </div>
);

export default Loader;
