import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { inject, observer } from 'mobx-react';

import { is, stopPropagation } from '../../utils/global';

const ModalWrapper = lazy(() => import('./components/ModalWrapper'));
const InfoModal = lazy(() => import('./components/InfoModal'));
const CropImage = lazy(() => import('./components/CropImage'));
const AddToProfile = lazy(() => import('./components/AddToProfile'));
const AddKeyPartner = lazy(() => import('./components/AddKeyPartner'));
const AddProjectPhoto = lazy(() => import('./components/AddProjectPhoto'));
const AddNewPartner = lazy(() => import('./components/AddNewPartner'));
const EditOurProjects = lazy(() => import('./components/EditOurProjects'));
const ConfirmDiscardChanges = lazy(() =>
  import('./components/ConfirmDiscardChanges')
);
const EmailSent = lazy(() => import('./components/EmailSent'));
const DeleteItem = lazy(() => import('./components/DeleteItem'));
const DeleteAccount = lazy(() => import('./components/DeleteAccount'));
const ViewInvoices = lazy(() => import('./components/ViewInvoices'));
const PdfOptions = lazy(() => import('./components/PdfOptions'));
const SignUpInvitation = lazy(() => import('./components/SignUpInvitation'));
const CreateList = lazy(() => import('./components/CreateList'));
const EditList = lazy(() => import('./components/EditList'));
const DeleteConfirmation = lazy(() =>
  import('./components/DeleteConfirmation')
);
const EditListItem = lazy(() => import('./components/EditListItem'));
const AddToList = lazy(() => import('./components/AddToList'));
const LeaveReview = lazy(() => import('./components/LeaveReview'));
const ConfirmReview = lazy(() => import('./components/ConfirmReview'));
const DeleteFromListConfirmation = lazy(() =>
  import('./components/DeleteFromListConfirmation')
);
const PdfFromList = lazy(() => import('./components/PdfFromList'));

const Modal = ({ modal }) => {
  function onBackgroundClick(event) {
    stopPropagation(event);
    if (is(event.target.className, 'modal')) modal.close();
  }

  if (!modal.isOpen) return null;

  const getEditTitle = () => {
    const array = modal.type.split('_');
    const item = array.slice(-1);
    return `Edit ${item}`;
  };

  return ReactDOM.createPortal(
    <Suspense fallback={<div className="loader" />}>
      <aside className="modal" role="dialog" onClick={onBackgroundClick}>
        {is(modal.type, 'ADDRESS') && (
          <ModalWrapper title="Offices" close={modal.close} type={modal.type}>
            <InfoModal>
              {modal.options.addressList &&
                modal.options.addressList.map((address, index) => (
                  <div key={index} className="icon-map-pin info-modal-li">
                    {address}
                  </div>
                ))}
              {modal.options.officesList &&
                modal.options.officesList.map(
                  (item, index) =>
                    !item.head_office && (
                      <div key={index} className="icon-map-pin info-modal-li">
                        {item.address}, {item.city.name},{' '}
                        {item.city.country.name}
                      </div>
                    )
                )}
            </InfoModal>
          </ModalWrapper>
        )}
        {is(modal.type, 'AWARDS') && (
          <ModalWrapper title="Awards" close={modal.close} type={modal.type}>
            <InfoModal>
              <ul className="style-disc">
                {modal.options.awardsList.map((item, index) =>
                  typeof item === 'string' ? (
                    <li key={index} className="info-modal-li">
                      {item}
                    </li>
                  ) : (
                    <li key={index} className="info-modal-li">
                      {item.name}, {item.year}, {item.city.name},{' '}
                      {item.city.country.name}
                    </li>
                  )
                )}
              </ul>
            </InfoModal>
          </ModalWrapper>
        )}
        {is(modal.type, 'CROP_IMAGE') && (
          <ModalWrapper
            title="Upload Cover photo"
            close={modal.close}
            type={modal.type}>
            <CropImage options={modal.options} close={modal.close} />
          </ModalWrapper>
        )}
        {(is(modal.type, 'ADD_OFFICE') ||
          is(modal.type, 'ADD_AWARD') ||
          is(modal.type, 'ADD_PROJECT')) && (
          <ModalWrapper
            title={`${
              modal.options.model
                ? getEditTitle()
                : modal.type.replace(/_/g, ' ')
            }`}
            close={modal.close}
            type={modal.type}>
            <AddToProfile
              close={modal.close}
              type={modal.type}
              options={modal.options}
            />
          </ModalWrapper>
        )}
        {is(modal.type, 'EDIT_OUR_PROJECTS') && (
          <ModalWrapper
            title="Our Project"
            close={modal.close}
            type={modal.type}>
            <EditOurProjects options={modal.options} />
          </ModalWrapper>
        )}
        {is(modal.type, 'ADD_KEY_PARTNER') && (
          <ModalWrapper
            title="Upload partner photo"
            close={modal.close}
            type={modal.type}>
            <AddKeyPartner options={modal.options} close={modal.close} />
          </ModalWrapper>
        )}
        {is(modal.type, 'ADD_PROJECT_PHOTO') && (
          <ModalWrapper
            title="Upload project photo"
            close={modal.close}
            type={modal.type}>
            <AddProjectPhoto options={modal.options} close={modal.close} />
          </ModalWrapper>
        )}
        {(is(modal.type, 'ADD_NEW_VENDOR') ||
          is(modal.type, 'ADD_NEW_PARTNER')) && (
          <ModalWrapper
            title={`${modal.type.replace(/_/g, ' ')}`}
            close={modal.close}
            type={modal.type}>
            <AddNewPartner type={modal.type} />
          </ModalWrapper>
        )}
        {is(modal.type, 'CONFIRM_DISCARD_CHANGES') && (
          <ModalWrapper
            title="Discard changes"
            close={modal.close}
            type={modal.type}>
            <ConfirmDiscardChanges
              close={modal.close}
              options={modal.options}
            />
          </ModalWrapper>
        )}
        {is(modal.type, 'EMAIL_SENT') && <EmailSent close={modal.close} />}
        {is(modal.type, 'DELETE_ITEM') && (
          <DeleteItem options={modal.options} close={modal.close} />
        )}
        {is(modal.type, 'DELETE_ACCOUNT') && (
          <DeleteAccount options={modal.options} close={modal.close} />
        )}
        {is(modal.type, 'VIEW_INVOICES') && (
          <ViewInvoices options={modal.options} close={modal.close} />
        )}
        {is(modal.type, 'PDF_OPTIONS') && <PdfOptions close={modal.close} />}
        {is(modal.type, 'LIST_PDF_OPTIONS') && (
          <PdfFromList close={modal.close} options={modal.options} />
        )}
        {is(modal.type, 'SIGN_UP_INVITATION') && (
          <SignUpInvitation close={modal.close} />
        )}
        {is(modal.type, 'CREATE_LIST') && (
          <ModalWrapper
            title="Create list"
            close={modal.close}
            type={modal.type}>
            <CreateList options={modal.options} />
          </ModalWrapper>
        )}
        {is(modal.type, 'EDIT_LIST') && (
          <EditList close={modal.close} options={modal.options} />
        )}
        {is(modal.type, 'DISCARD_LIST') && (
          <ModalWrapper
            title="Discard list"
            close={modal.close}
            type={modal.type}>
            <DeleteConfirmation options={modal.options} />
          </ModalWrapper>
        )}
        {is(modal.type, 'DELETE_FROM_LIST') && (
          <ModalWrapper
            title="Delete from list"
            close={modal.close}
            type={modal.type}>
            <DeleteFromListConfirmation options={modal.options} />
          </ModalWrapper>
        )}
        {is(modal.type, 'EDIT_ITEM') && (
          <EditListItem close={modal.close} options={modal.options} />
        )}
        {is(modal.type, 'ADD_TO_LIST') && (
          <AddToList close={modal.close} options={modal.options} />
        )}
        {is(modal.type, 'LEAVE_REVIEW') && (
          <ModalWrapper
            title={
              modal.options.editableReview ? 'Edit review' : 'Write review'
            }
            close={modal.close}
            type={modal.type}>
            <LeaveReview options={modal.options} />
          </ModalWrapper>
        )}
        {is(modal.type, 'CONFIRM_REVIEW') && (
          <ModalWrapper
            title="Confirm review"
            close={modal.close}
            type={modal.type}>
            <ConfirmReview options={modal.options} />
          </ModalWrapper>
        )}
      </aside>
    </Suspense>,
    document.body
  );
};

export default inject('modal')(observer(Modal));
