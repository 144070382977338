import React, { Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { inject } from 'mobx-react';

import Layout from '../layouts/index';
import Loader from '../components/common/Loader';
import { ROUTES } from '../constants';

const LandingPage = lazy(() => import('../components/landing'));
const Confidentiality = lazy(() => import('../components/Confidentiality'));
const ProfileConstants = lazy(() => import('../components/profileConstants'));
const EditProfile = lazy(() => import('../components/profile/edit'));
const HowItWorks = lazy(() => import('../components/HowItWorks'));
const Privacy = lazy(() => import('../components/Privacy'));
const TermsAndConditions = lazy(() =>
  import('../components/TermsAndConditions')
);
const BrandsAndDevelopers = lazy(() =>
  import('../components/BrandsAndDevelopers')
);
const IndustryLeaders = lazy(() => import('../components/industryLeaders'));
const PartnerWithUs = lazy(() => import('../components/partnerWithUs'));
const ContactForm = lazy(() =>
  import('../components/partnerWithUs/ContactForm')
);
const EmailVerification = lazy(() =>
  import('../components/auth/EmailVerification')
);
const CreatePassword = lazy(() => import('../components/auth/CreatePassword'));
const Login = lazy(() => import('../components/auth/Login'));
const NoMatchPage = lazy(() => import('../components/errorsPage/NoMatchPage'));
const Profile = lazy(() => import('../components/profile'));
const AccountSettings = lazy(() => import('../components/settings'));
const ForgotPassword = lazy(() => import('../components/auth/ForgotPassword'));
const SubscriptionPlans = lazy(() =>
  import('../components/auth/subscription/SubscriptionPlans')
);
const SuccessfulSubscription = lazy(() =>
  import('../components/auth/subscription/SuccessfulSubscription')
);
const SearchPage = lazy(() => import('../components/search'));
const Notifications = lazy(() => import('../components/notifications'));
const ShortLists = lazy(() => import('../components/shortLists'));
const ShortList = lazy(() => import('../components/shortLists/list'));

const ReverifyEmail = lazy(() => import('../components/auth/ReverifyEmail'));

const UnAuthorizedRoute = inject('auth')(
  ({ auth, component: Component, render, ...rest }) => {
    return (
      <Route
        {...rest}
        render={(props) =>
          !auth.isLogin ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: ROUTES.settings,
              }}
            />
          )
        }
      />
    );
  }
);

const PrivateRoute = inject('auth')(
  ({ auth, component: Component, render, ...rest }) => {
    return (
      <Route
        {...rest}
        render={(props) =>
          auth.isLogin ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: ROUTES.login,
                state: { previousUrl: window.location.pathname },
              }}
            />
          )
        }
      />
    );
  }
);

const Routes = () => (
  <Layout>
    <Suspense fallback={<Loader />}>
      <Switch>
        <Route exact path={ROUTES.root} component={LandingPage} />
        <Route
          exact
          path={ROUTES.confidentiality}
          component={Confidentiality}
        />
        <Route exact path={ROUTES.profile} component={ProfileConstants} />
        <PrivateRoute exact path={ROUTES.editProfile} component={EditProfile} />
        <PrivateRoute
          exact
          path={ROUTES.consultantSearch}
          component={SearchPage}
        />
        <PrivateRoute
          exact
          path={ROUTES.consultant}
          component={(props) => <Profile {...props} />}
        />
        <PrivateRoute
          exact
          path={ROUTES.vendor}
          component={(props) => <Profile {...props} />}
        />
        <PrivateRoute
          exact
          path={ROUTES.brandsDevelopers}
          component={BrandsAndDevelopers}
        />
        <PrivateRoute
          exact
          path={ROUTES.industryLeaders}
          component={IndustryLeaders}
        />
        <Route exact path={ROUTES.howItWorks} component={HowItWorks} />
        <Route exact path={ROUTES.privacy} component={Privacy} />
        <Route exact path={ROUTES.terms} component={TermsAndConditions} />
        <Route exact path={ROUTES.partnerWithUs} component={PartnerWithUs} />
        <UnAuthorizedRoute
          exact
          path={ROUTES.joinAsConsultant}
          component={(props) => <ContactForm formType="consultant" />}
        />
        <UnAuthorizedRoute
          exact
          path={ROUTES.joinAsDeveloper}
          component={(props) => <ContactForm formType="developer" />}
        />
        <UnAuthorizedRoute
          exact
          path={ROUTES.joinAsBrand}
          component={(props) => <ContactForm formType="brand" />}
        />
        <UnAuthorizedRoute
          exact
          path={ROUTES.joinAsVendor}
          component={(props) => <ContactForm formType="vendor" />}
        />
        <UnAuthorizedRoute
          exact
          path={ROUTES.emailVerification}
          component={EmailVerification}
        />
        <UnAuthorizedRoute
          exact
          path={ROUTES.resendEmailVerification}
          component={ReverifyEmail}
        />
        <UnAuthorizedRoute
          exact
          path={ROUTES.createPassword}
          component={CreatePassword}
        />
        <UnAuthorizedRoute exact path={ROUTES.login} component={Login} />
        <UnAuthorizedRoute
          exact
          path={ROUTES.forgotPassword}
          component={ForgotPassword}
        />
        <PrivateRoute
          exact
          path={ROUTES.settings}
          component={AccountSettings}
        />
        <Route exact path={ROUTES.subscription} component={SubscriptionPlans} />
        <Route
          exact
          path={ROUTES.successfulSubscription}
          component={SuccessfulSubscription}
        />
        <PrivateRoute
          exact
          path={ROUTES.notifications}
          component={Notifications}
        />
        <PrivateRoute exact path={ROUTES.shortLists} component={ShortLists} />
        <PrivateRoute exact path={ROUTES.shortList} component={ShortList} />
        <Route component={NoMatchPage} />
      </Switch>
    </Suspense>
  </Layout>
);

export default Routes;
