import { types as t, flow, getEnv, getRoot } from 'mobx-state-tree';

import { is } from '../utils/global';

const Brand = t
  .model('Brand', {
    city: t.frozen(),
    contact_person: t.maybeNull(t.string),
    corporate_website_url: t.maybeNull(t.frozen()),
    email: t.maybeNull(t.string),
    id: t.identifierNumber,
    name: t.string,
    phone_number: t.maybeNull(t.string),
    logo: t.maybeNull(t.frozen()),
    role: 'Brand',
  })
  .actions((self) => ({
    deleteLogo: flow(function* (id) {
      const { http } = getEnv(self);
      const { auth } = getRoot(self);

      const response = yield http.delete(`/attachments/${id}`);

      if (is(response.status, 204)) {
        self.logo = null;
        auth.getSession();
      }

      if (is(response.status, 401)) {
        auth.setUnauthorized();
      }
    }),

    updateEmail: flow(function* (data) {
      const { http } = getEnv(self);
      const { snackBars, auth } = getRoot(self);

      const body = {
        brand: data,
      };

      const response = yield http.patch(
        `/brands/${self.id}/update_email`,
        body
      );

      if (is(response.status, 200)) {
        const { message } = yield response.json();

        snackBars.addNotification({
          message: message,
          options: {
            variant: 'success',
          },
        });

        self.email = data.email;

        auth.getSession();
      }

      if (is(response.status, 401)) {
        auth.setUnauthorized();
      }

      if (is(response.status, 422)) {
        const { error } = yield response.json();

        snackBars.addNotification({
          message: error,
          options: {
            variant: 'error',
          },
        });
      }
    }),
  }))
  .views((self) => ({}));

export { Brand };
