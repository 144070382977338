const PUBLIC_URL = process.env.PUBLIC_URL;
const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

const REG_EXP_EMAIL =
  /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;

const REG_EXP_NUMBER = /^\d{1,3}$/;
const REG_EXP_URL = /^(ftp|http|https):\/\/[^ "]+$/;

const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;

const CATEGORIES = [
  {
    name: 'Interior Designer',
    image: `interior-designer`,
  },
  {
    name: 'Architect',
    image: `architect`,
  },
  {
    name: 'Lighting consultant',
    image: `lighting-consultant`,
  },
  {
    name: 'Art/Signage Consultant',
    image: `art-consultant`,
  },
  {
    name: 'SPA consultant',
    image: `spa-consultant`,
  },
  {
    name: 'Landscape architect',
    image: `landscape-architect`,
  },
  {
    name: 'Food & Beverage consultant',
    image: `food-consultant`,
  },
  {
    name: 'Project Management',
    image: `purchasing-agent`,
  },
];

const ROUTES = {
  root: '/',
  confidentiality: '/confidentiality',
  profile: '/profile/:id',
  howItWorks: '/how-it-works',
  privacy: '/privacy-policy',
  terms: '/terms-and-conditions',
  consultant: '/consultants/:id',
  consultantSearch: '/consultants/search',
  editProfile: '/edit',
  vendor: '/vendors/:id',
  brandsDevelopers: '/brands-and-developers',
  industryLeaders: '/industry-leaders',
  //
  partnerWithUs: '/partner-with-us',
  joinAsConsultant: '/partner-with-us/consultant',
  joinAsDeveloper: '/partner-with-us/developer',
  joinAsBrand: '/partner-with-us/brand',
  joinAsVendor: '/partner-with-us/vendor',
  //
  emailVerification: '/email-verification',
  resendEmailVerification: '/resend-email-verification',
  createPassword: '/create-password',
  login: '/login',
  settings: '/settings',
  forgotPassword: '/forgot-password',
  subscription: '/subscription',
  successfulSubscription: '/subscription-successful',
  shortLists: '/shortlists',
  shortList: '/shortlists/:listId',
  //
  notifications: '/notifications',
};

const ERRORS = {
  error403: {
    text: 'Error 403. Forbidden.',
    backgroundText: '403',
  },
  error404: {
    text: 'Error 404. Page not found.',
    backgroundText: '404',
  },
  error500: {
    text: 'Error 500. Server error.',
    backgroundText: '500',
  },
  error503: {
    text: 'Error 503. Service temporarily unavailable. Please try again later.',
    backgroundText: '503',
  },
};

const MAX_IMAGE_SIZE = 12000000;
const COVER_IMAGE_DIMENSIONS = {
  min_width: 828,
  min_height: 936,
};
const LOGO_IMAGE_DIMENSIONS = {
  min_width: 166,
  min_height: 166,
};
const PARTNER_IMAGE_DIMENSIONS = {
  min_width: 828,
  min_height: 1043,
};
const LANDSCAPE_PROJECT_IMAGE_DIMENSIONS = {
  min_width: 1440,
  min_height: 0,
};
const PROJECT_IMAGE_DIMENSIONS = {
  min_width: 828,
  min_height: 1044,
};
const REQUIRED_COVER_IMAGE_DIMENSIONS = {
  x: 828,
  y: 936,
};
const REQUIRED_LOGO_IMAGE_DIMENSIONS = {
  x: 1,
  y: 1,
};
const REQUIRED_PARTNER_IMAGE_DIMENSIONS = {
  x: 552,
  y: 825,
};
const REQUIRED_LANDSCAPE_PROJECT_IMAGE_DIMENSIONS = {
  x: 1.63,
  y: 1,
};
const REQUIRED_PROJECT_IMAGE_DIMENSIONS = {
  x: 414,
  y: 522,
};

const MAX_PROJECTS_LENGTH = 8;

const PASSWORD_INPUTS = [
  {
    name: 'password',
    label: 'Current Password',
    placeholder: 'Password',
  },
  {
    name: 'confirm_password',
    label: 'New Password',
    placeholder: 'Confirm password',
  },
];

const SORT_BY = [
  {
    id: 'best_rating',
    displayedName: 'Best rated',
    order: 'desc',
    field: 'best_rating',
  },
  {
    id: 'years_of_experience',
    displayedName: 'Experience (high-low)',
    order: 'desc',
    field: 'years_of_experience',
  },
  {
    id: '-years_of_experience',
    displayedName: 'Experience (low-high)',
    order: 'asc',
    field: 'years_of_experience',
  },
  {
    id: '-name',
    displayedName: 'Alphabetical (A-Z)',
    order: 'ASC',
    field: 'name',
  },
  {
    id: 'name',
    displayedName: 'Alphabetical (Z-A)',
    order: 'DESC',
    field: 'name',
  },
];

const PDF_OPTIONS = ['5', '10', '25', 'All'];

const REGISTRATION_TYPE_BUTTONS = [
  {
    title: 'Join as a Consultant',
    text: 'Be considered and hired for hospitality, retail, and condominium industry projects around the world.',
    type: 'consultant',
  },
  {
    title: 'Join as a Developer',
    text: 'It is an easy to use platform that allows to view and hier industry professionals and leaders for your projects who only commit to deliver outstanding creativity and superior return on quality hospitality, retail and condominium investment.',
    type: 'developer',
  },
  {
    title: 'Join as Brand',
    text: 'VIPrfp facilitates communication between the internationally recognized hotel Brands, Developers/Owners and industry leaders in a single platform with the aim of putting together a dream team for your next project.',
    type: 'brand',
  },
];

export {
  PUBLIC_URL,
  REACT_APP_API_URL,
  REG_EXP_EMAIL,
  REG_EXP_NUMBER,
  REG_EXP_URL,
  SENTRY_DSN,
  CATEGORIES,
  ROUTES,
  ERRORS,
  MAX_IMAGE_SIZE,
  COVER_IMAGE_DIMENSIONS,
  LOGO_IMAGE_DIMENSIONS,
  PARTNER_IMAGE_DIMENSIONS,
  LANDSCAPE_PROJECT_IMAGE_DIMENSIONS,
  PROJECT_IMAGE_DIMENSIONS,
  REQUIRED_COVER_IMAGE_DIMENSIONS,
  REQUIRED_LOGO_IMAGE_DIMENSIONS,
  REQUIRED_PARTNER_IMAGE_DIMENSIONS,
  REQUIRED_LANDSCAPE_PROJECT_IMAGE_DIMENSIONS,
  REQUIRED_PROJECT_IMAGE_DIMENSIONS,
  MAX_PROJECTS_LENGTH,
  PASSWORD_INPUTS,
  SORT_BY,
  PDF_OPTIONS,
  REGISTRATION_TYPE_BUTTONS,
};
