import React from 'react';
import { SnackbarProvider } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';

const SNACK_BAR_SETTINGS = {
  autoHideDuration: 3000,
  anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
  maxSnack: 1,
};

const styles = {
  success: {
    backgroundColor: '#3E9146',
  },
  error: { backgroundColor: 'red' },
  warning: { backgroundColor: '#e4982d' },
  info: { backgroundColor: 'blue' },
};

const useStyles = makeStyles(styles);

const SnackbarProviderWrapper = (props) => {
  const classes = useStyles();

  return (
    <SnackbarProvider
      classes={{
        variantSuccess: classes.success,
        variantError: classes.error,
        variantWarning: classes.warning,
        variantInfo: classes.info,
      }}
      {...SNACK_BAR_SETTINGS}>
      {props.children}
    </SnackbarProvider>
  );
};

export default SnackbarProviderWrapper;
