import { types as t } from 'mobx-state-tree';

import { ShortList } from '../models/ShortList';

const ListsDB = t
  .model('ListsDB', {
    list: t.optional(t.map(ShortList), {}),
  })
  .actions((self) => ({
    put(list: typeof ShortList) {
      let newList = list;
      const oldList = self.get(list.id);

      if (oldList) {
        newList = Object.assign(oldList, newList);
      }
      return self.list.put(newList);
    },

    get(id: number) {
      return self.list.get(id.toString());
    },

    byIds(ids) {
      return ids.map(self.get);
    },
  }));

export { ListsDB };
