import { types, flow, getEnv, getRoot } from 'mobx-state-tree';

import { is, createController } from '../utils/global';
import { ROUTES } from '../constants';
import { Pagination } from './Global';
import { Consultant } from '../models/Consultant';

let controller;

const ConsultantsOptions = types.model('ConsultantsOptions', {
  list: types.optional(types.array(types.reference(Consultant)), []),
  pagination: Pagination,
  loading: false,
});

const Search = types
  .model('Search', {
    consultants: types.optional(ConsultantsOptions, {}),
    byCategory: types.maybeNull(types.frozen()),
    loading: true,
    isRedirected: false,
    params: types.optional(
      types.model('Params', {
        name: types.optional(types.string, ''),
        category_ids: types.optional(types.frozen(), []),
        brand_ids: types.optional(types.frozen(), []),
        sub_category_ids: types.optional(types.frozen(), []),
        location: types.optional(types.string, ''),
        max_experience: types.optional(types.number, 100),
        min_experience: types.optional(types.number, 1),
        field: 'name',
        order: 'ASC',
      }),
      {}
    ),
    pdf_options: types.optional(ConsultantsOptions, {}),
  })
  .actions((self) => ({
    afterAttach() {
      controller = createController();
    },

    fetchConsultants: flow(function* () {
      const { http } = getEnv(self);
      const { auth, consultants_db } = getRoot(self);

      if (self.consultants.loading) {
        controller.abort();
        controller = createController();
      }

      const query = `?${self.makeQuery(self.params)}`;

      self.consultants.loading = true;

      const response = yield http.get(`/design_agencies${query}`, null, {
        signal: controller.signal,
      });

      if (is(response.status, 200)) {
        const { design_agencies, pagination } = yield response.json();

        self.consultants.pagination = pagination;

        design_agencies.forEach((consultant) => {
          consultants_db.put(consultant);

          self.consultants.list.push(consultant.id);
        });
        self.consultants.loading = false;
      }

      if (is(response.status, 401)) {
        auth.setUnauthorized();
        self.consultants.loading = false;
      }
    }),

    setNewSearch() {
      self.consultants.pagination.current_page = 0;
      self.consultants.pagination.total_pages = 0;
      self.consultants.list = [];

      self.pdf_options.pagination.current_page = 0;
      self.pdf_options.pagination.total_pages = 0;
      self.pdf_options.list = [];
    },

    makeQuery(params, forPdf) {
      const currentPage = forPdf
        ? self.pdf_options.pagination.current_page
        : self.consultants.pagination.current_page;

      params.page = currentPage + 1;

      return Object.keys(params)
        .map((key) => {
          if (Array.isArray(params[key])) {
            return params[key]
              .map((value, index) => `${key}[${index}]=${value}`)
              .join('&');
          }
          return (
            encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
          );
        })
        .join('&');
    },

    setSelectedCategories(categoryIds) {
      self.params.category_ids = categoryIds;
    },

    setSelectedSubCategories(subCategoryIds) {
      self.params.sub_category_ids = subCategoryIds;
    },

    setSelectedBrands(brandIds) {
      self.params.brand_ids = brandIds;
    },

    setName(name: String) {
      self.setNewSearch();
      self.params.name = name;
    },

    setLocation(location) {
      self.setNewSearch();
      self.params.location = location;
    },

    setRangeValue(value) {
      self.params.min_experience = value.min;
      self.params.max_experience = value.max > 9 ? 100 : value.max;
    },

    setOrdering(value: Object) {
      self.setNewSearch();
      self.params.field = value.field;
      self.params.order = value.order;

      self.fetchConsultants();
    },

    setSearchByCategory(categoryId) {
      const { history } = getEnv(self);

      self.setNewSearch();
      self.params.category_ids = categoryId;

      self.params.name = '';

      // clear subcategories
      self.params.sub_category_ids = [];
      self.clearCheckBoxes('sub-category--js');

      // clear worked with brands
      self.params.brand_ids = [];
      self.clearCheckBoxes('brand--js');

      self.params.location = '';
      self.params.max_experience = 100;
      self.params.min_experience = 1;
      self.params.field = 'name';
      self.params.order = 'ASC';

      setTimeout(() => history.push(ROUTES.consultantSearch), 0);
    },

    clearAllFilters() {
      self.params.name = '';
      self.params.category_ids = [];
      self.params.brand_ids = [];
      self.params.sub_category_ids = [];
      self.params.location = '';
      self.params.max_experience = 100;
      self.params.min_experience = 1;
      self.params.field = 'name';
      self.params.order = 'ASC';
    },

    clearCheckBoxes(className) {
      [...document.getElementsByClassName(className)].map((input) => {
        if (input.checked) {
          input.checked = !input.checked;
        }
        return null;
      });
    },

    checkSelectedCategoryCheckbox(categoryId) {
      [...document.getElementsByClassName('category--js')].map((input) => {
        const inputId = input.getAttribute('id');

        if (+inputId === +categoryId) {
          input.checked = true;
        }
        if (input.checked && +inputId !== +categoryId) {
          input.checked = !input.checked;
        }
        return null;
      });
    },

    searchPartners: flow(function* (params) {
      const { http } = getEnv(self);
      const { auth, consultants_db } = getRoot(self);

      if (self.consultants.loading) {
        controller.abort();
        controller = createController();
      }

      const query = `?${self.makeQuery(params)}`;

      self.consultants.loading = true;

      const response = yield http.get(`/design_agencies${query}`, null, {
        signal: controller.signal,
      });

      if (is(response.status, 200)) {
        const { design_agencies, pagination } = yield response.json();

        self.consultants.pagination = pagination;

        design_agencies.forEach((consultant) => {
          consultants_db.put(consultant);

          self.consultants.list.push(consultant.id);
        });
      }

      if (is(response.status, 401)) {
        auth.setUnauthorized();
      }

      self.consultants.loading = false;
    }),

    setIsRedirected(value: Boolean) {
      self.isRedirected = value;
    },

    fetchPdfList: flow(function* () {
      const { http } = getEnv(self);
      const { auth, consultants_db } = getRoot(self);

      const query = `?${self.makeQuery(self.params, true)}`;

      self.pdf_options.loading = true;

      const response = yield http.get(`/design_agencies${query}`, null, {});

      if (is(response.status, 200)) {
        const { design_agencies, pagination } = yield response.json();

        self.pdf_options.pagination = pagination;

        design_agencies.forEach((consultant) => {
          consultants_db.put(consultant);

          self.pdf_options.list.push(consultant.id);
        });

        if (pagination.total_pages > pagination.current_page) {
          self.fetchNextPage();
        } else self.pdf_options.loading = false;
      }

      if (is(response.status, 401)) {
        auth.setUnauthorized();
        self.pdf_options.loading = false;
      }
    }),

    fetchNextPage() {
      self.fetchPdfList();
    },
  }));

export { Search };
