import React from 'react';
import { NavLink } from 'react-router-dom';
import { observer, inject } from 'mobx-react';

import { ROUTES } from '../constants';
import CategoryDropdown from '../components/common/CategoryDropdown';
import { stopPropagation, is } from '../utils/global';

const Footer = ({ auth, modal }) => {
  function onScrollToTop(event) {
    event.preventDefault();
    window.scrollTo({ top: 0 });
  }

  function openModal() {
    modal.open('SIGN_UP_INVITATION', {});
  }

  return (
    <footer className='footer' role='contentinfo'>
      <div className='footer__wrapper' onClick={onScrollToTop}>
        <div className='footer__logo'>
          <NavLink to={ROUTES.root}>
            <img
              src={require(`../assets/logo-white.svg`).default}
              alt='VIPRFP'
            />
          </NavLink>
        </div>
        <div className='footer__nav-wrapper'>
          <nav onClick={stopPropagation}>
            <ul className='footer__nav' role='navigation'>
              {auth.isLogin ? (
                <>
                  <li>
                    <NavLink to={ROUTES.industryLeaders}>
                      Industry Leaders
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={ROUTES.brandsDevelopers}>
                      Brands & Developers
                    </NavLink>
                  </li>
                  {(is(auth.me.role, 'Brand') ||
                    is(auth.me.role, 'Developer')) && (
                    <li>
                      <CategoryDropdown
                        type={'footer'}
                        onCloseMenu={() => {}}
                      />
                    </li>
                  )}
                </>
              ) : (
                <>
                  <li onClick={openModal}>
                    <span>Industry Leaders</span>
                  </li>
                  <li onClick={openModal}>
                    <span> Brands & Developers</span>
                  </li>
                  <li onClick={openModal}>
                    <span>Categories</span>
                  </li>
                </>
              )}
              <li>
                <NavLink to={ROUTES.confidentiality}>Confidentiality</NavLink>
              </li>
              <li>
                <NavLink to={ROUTES.howItWorks}>How It Works</NavLink>
              </li>
            </ul>
          </nav>
          <div className='footer__buttons-wrapper'>
            {/* <button className="btn-rounded icon-instagram" />
            <button className="btn-rounded icon-linkedin" />
            <button className="btn-rounded icon-facebook" /> */}
          </div>
        </div>
        <div className='footer__bottom'>
          <div className='footer__bottom-nav' role='navigation'>
            <NavLink to={ROUTES.privacy}>Privacy Policy</NavLink>
            <NavLink to={ROUTES.terms}>Terms of Service</NavLink>
          </div>
          <div>© VIPrfp 2022, All Rights Reserved</div>
        </div>
      </div>
    </footer>
  );
};

export default inject('auth', 'modal')(observer(Footer));
