import React, { useState, useEffect } from 'react';
import Types from 'prop-types';
import { Link } from 'react-router-dom';
import { observer, inject } from 'mobx-react';

import { ROUTES } from '../../../constants';
import { is, dateTime, stopPropagation } from '../../../utils/global';
import DEFAULT_USERPIC_URL from '../../../assets/icons/default-userpic.svg';
import iconSrc from '../../../assets/icons/warning.png';

const Notification = ({
  notification,
  toggleOpenDropdown,
  makeReadNotification,
  me,
  myProfile,
  modal,
}) => {
  const [logo, setLogo] = useState('');
  const [name, setName] = useState('');
  const [isLongText, setLongText] = useState(false);
  const [isOpenText, setIsOpenText] = useState(false);
  const [isUnread, setIsUnread] = useState(false);
  const [isNotChanged, setChanged] = useState(true);
  const [requestState, setRequestState] = useState('');
  const [profileLink, setProfileLink] = useState('');

  useEffect(() => {
    const { notification_type, content } = notification;

    if (notification.is_new) setIsUnread(true);

    if (is(notification_type, 'brand_approval_request')) {
      content.requester_logo && setLogo(content.requester_logo.url);
      setName(content.requester_name);

      const link = `${
        is(content.requester_type, 'Vendor') ? '/vendors/' : '/consultants/'
      }${content.requester_id}`;

      setProfileLink(link);
    }

    if (
      is(notification_type, 'brand_approval_declined') ||
      is(notification_type, 'brand_approval_approved')
    ) {
      content.brand_logo && setLogo(content.brand_logo.url);
      setName(content.brand_name);
    }

    if (is(notification_type, 'profile_view')) {
      content.viewer_logo && setLogo(content.viewer_logo.url);
      setName(content.viewer_name);
    }

    if (
      is(notification.notification_type, 'review_posted') ||
      is(notification.notification_type, 'review_created')
    ) {
      content.reviewer_logo && setLogo(content.reviewer_logo.url);
      setName(content.reviewer_name);
    }

    if (is(notification.notification_type, 'review_confirmation')) {
      content.reviewable_logo && setLogo(content.reviewable_logo.url);
      setName(content.reviewable_name);
      if (notification.content.review_text.length > 85) setLongText(true);

      const link = `${
        is(content.reviewable_type, 'Vendor') ? '/vendors/' : '/consultants/'
      }${content.reviewable_id}`;
      setProfileLink(link);
    }
  }, [notification]);

  function markIsRead(event) {
    stopPropagation(event);

    if (notification.is_new) {
      setIsUnread(false);
      makeReadNotification(notification.id);
    }
  }

  function approveRequest(event) {
    stopPropagation(event);
    if (notification.is_new) markIsRead(event);

    if (
      is(notification.notification_type, 'brand_approval_request') &&
      is(me.role, 'Brand')
    ) {
      myProfile.approveRequest(notification.content.brand_approval_id);
      setChanged(false);
      setRequestState('Request approved');
    }
  }

  function rejectRequest(event) {
    stopPropagation(event);
    if (notification.is_new) markIsRead(event);

    if (
      is(notification.notification_type, 'brand_approval_request') &&
      is(me.role, 'Brand')
    ) {
      myProfile.declineRequest(notification.content.brand_approval_id);
      setChanged(false);
      setRequestState('Request declined');
    }
  }

  function hideReviewButtons() {
    setChanged(false);
  }

  function confirmReview(event) {
    stopPropagation(event);
    if (notification.is_new) markIsRead(event);

    if (+notification.content.review_rating < 4) {
      modal.open('CONFIRM_REVIEW', {
        confirmReview: myProfile.confirmReview,
        deleteReview: myProfile.deleteReview,
        reviewId: notification.content.review_id,
        onSuccess: hideReviewButtons,
      });
    } else
      myProfile.confirmReview(
        notification.content.review_id,
        hideReviewButtons
      );
  }

  function deleteReview(event) {
    stopPropagation(event);
    if (notification.is_new) markIsRead(event);

    myProfile.deleteReview(notification.content.review_id, hideReviewButtons);
    setRequestState('You deleted review');
  }

  function toggleTextBlock(event) {
    stopPropagation(event);

    if (notification.is_new) markIsRead(event);
    setIsOpenText(!isOpenText);
  }

  function getNotificationContent() {
    const { notification_type, content, created_at } = notification;
    const { result, brand_approval_id, review_text } = content;

    if (is(notification_type, 'brand_approval_request')) {
      return (
        <>
          <p onClick={toggleOpenDropdown}>
            <strong>
              <Link to={profileLink}>{name} </Link>
            </strong>
            <span> would like you to approve working with it.</span>
          </p>
          {((result && is(result, 'Request declined')) ||
            is(requestState, 'Request declined')) && (
            <p className="gray-text small">Request declined.</p>
          )}
          {((result && is(result, 'Request approved')) ||
            is(requestState, 'Request approved')) && (
            <p className="gray-text small">Request approved.</p>
          )}
          {isNotChanged && !result && brand_approval_id && (
            <div className="notification-card__btns-wrapper">
              <button
                type="button"
                className="btn-primary"
                onClick={approveRequest}>
                Approve
              </button>
              <button
                type="button"
                className="btn-secondary"
                onClick={rejectRequest}>
                Reject
              </button>
            </div>
          )}
        </>
      );
    }
    if (is(notification_type, 'brand_approval_declined')) {
      return (
        <p>
          <strong>{name} </strong> <span>declined your request.</span>
        </p>
      );
    }
    if (is(notification_type, 'brand_approval_approved')) {
      return (
        <p>
          <strong>{name} </strong> <span>approved your request.</span>
        </p>
      );
    }
    if (is(notification_type, 'profile_view')) {
      return (
        <div onClick={toggleOpenDropdown}>
          <p>
            <strong>{name} </strong> <span>viewed your profile.</span>
          </p>
          <Link to={ROUTES.notifications} className="simple-link">
            See all views
          </Link>
        </div>
      );
    }

    if (is(notification_type, 'review_posted')) {
      return (
        <p>
          <strong>{name} </strong> <span> left a review about you.</span>
        </p>
      );
    }

    if (is(notification_type, 'review_confirmation')) {
      return (
        <>
          <p onClick={toggleOpenDropdown}>
            {result || (!isNotChanged && !requestState)
              ? 'You have confirmed a review to '
              : !isNotChanged && is(requestState, 'You deleted review')
              ? 'You deleted review to '
              : 'Please, confirm posting a review you left to '}
            <strong>
              <Link to={profileLink}>{name} </Link>
            </strong>
            {dateTime(created_at)}.
          </p>
          <div
            className={`notification-card__collapsible-text${
              isOpenText ? '' : ' close'
            }`}
            onClick={toggleTextBlock}>
            <p className="gray-text">{review_text}</p>
            {isLongText && (
              <p className="small">{isOpenText ? 'Hide' : 'Show full'}</p>
            )}
          </div>
          {isNotChanged && !result && (
            <div
              className="notification-card__btns-wrapper"
              onClick={stopPropagation}>
              <button
                type="button"
                className="btn-primary"
                onClick={confirmReview}>
                Confirm
              </button>
              <button
                type="button"
                className="btn-secondary"
                onClick={deleteReview}>
                Delete
              </button>
            </div>
          )}
        </>
      );
    }

    if (is(notification_type, 'billing_problem')) {
      return (
        <p onClick={toggleOpenDropdown}>
          Your card was declined.{' '}
          <a
            href={content.billing_portal_url}
            target="_blank"
            rel="noopener noreferrer"
            className="simple-link">
            Please add another payment method
          </a>
        </p>
      );
    }

    if (is(notification_type, 'billing_problem')) {
      return (
        <p onClick={toggleOpenDropdown}>
          Your card was declined.{' '}
          <a
            href={content.billing_portal_url}
            target="_blank"
            rel="noopener noreferrer"
            className="simple-link">
            Please add another payment method
          </a>
        </p>
      );
    }
    if (is(notification_type, 'upcoming_invoice_week')) {
      return (
        <p>
          The following subscription will renew in a week. To avoid being
          charged, you must cancel at least a day before each renewal date.{' '}
          <a
            href={content.billing_portal_url}
            target="_blank"
            rel="noopener noreferrer"
            className="simple-link">
            Review subscription
          </a>
        </p>
      );
    }

    if (is(notification_type, 'upcoming_invoice_day')) {
      return (
        <p>
          The following subscription will renew <strong>tommorow</strong>. To
          avoid being charged, you must cancel at least a day before each
          renewal date.{' '}
          <a
            href={content.billing_portal_url}
            target="_blank"
            rel="noopener noreferrer"
            className="simple-link">
            Review subscription
          </a>
        </p>
      );
    }

    if (is(notification_type, 'review_created')) {
      return (
        <>
          <p>
            <strong>{name} </strong> <span> created a review about you.</span>
          </p>
          <div
            className={`notification-card__collapsible-text${
              isOpenText ? '' : ' close'
            }`}
            onClick={toggleTextBlock}>
            <p className="gray-text">{review_text}</p>
            {isLongText && (
              <p className="small">{isOpenText ? 'Hide' : 'Show full'}</p>
            )}
          </div>
        </>
      );
    }
  }

  const isWithoutLogo =
    is(notification.notification_type, 'billing_problem') ||
    is(notification.notification_type, 'upcoming_invoice_week') ||
    is(notification.notification_type, 'upcoming_invoice_day');

  return (
    <li className={`notification-card${isUnread ? ' unread' : ''}`}>
      <div className="notification-card__image" onClick={toggleOpenDropdown}>
        {profileLink && <Link to={profileLink} />}

        <img
          src={logo ? logo : isWithoutLogo ? iconSrc : DEFAULT_USERPIC_URL}
          alt={name}
          loading="lazy"
        />
      </div>
      <div className="notification-card__info" onClick={markIsRead}>
        {getNotificationContent()}
      </div>
      <div className="notification-card__details">
        {notification.content.viewer_type ||
        notification.content.reviewer_type ? (
          <div className="gray-badge">
            {notification.content.viewer_type ||
              notification.content.reviewer_type}
          </div>
        ) : (
          <div />
        )}
        <div className="gray-text">{dateTime(notification.created_at)}</div>
      </div>
    </li>
  );
};

Notification.propTypes = {
  notification: Types.object.isRequired,
  toggleOpenDropdown: Types.func.isRequired,
  me: Types.object,
  makeReadNotification: Types.func,
  isUnread: Types.bool,
  brandProfile: Types.object,
  modal: Types.object,
};

export default inject('modal')(observer(Notification));
