import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { isEmpty } from 'ramda';

class AuthWrapper extends Component {
  async componentDidMount() {
    if (this.props.auth.isLogin) await this.props.auth.getSession();

    if (isEmpty(this.props.global.categories)) {
      this.props.global.fetchCategories();
    }
  }

  render() {
    const { children, auth } = this.props;

    if (this.props.auth.isLogin && auth.login.state === 'pending')
      return <div className="loader" />;
    return children;
  }
}

export default inject('auth', 'global')(observer(AuthWrapper));
