import { isEmpty } from 'ramda';

import { is } from '../utils/global';

function fetchGlobal(global) {
  isEmpty(global.countries) && global.fetchCountries();
  isEmpty(global.categories) && global.fetchCategories();
  isEmpty(global.sub_categories) && global.fetchSubCategories();
  global.fetchBrands();
  global.fetchVendors();
}

async function getProfile(
  { global, consultant_profile, vendor_profile },
  match
) {
  fetchGlobal(global);

  if (match.path.includes(`/consultants`)) {
    await consultant_profile.fetchProfile(match.params.id);
  }

  if (match.path.includes(`/vendors`)) {
    await vendor_profile.fetchProfile(match.params.id);
  }
}

async function getOwnProfile({
  global,
  consultant_profile,
  vendor_profile,
  auth,
}) {
  fetchGlobal(global);

  if (is(auth.me.role, 'DesignAgency')) {
    await consultant_profile.fetchProfile(auth.me.id);
  }

  if (is(auth.me.role, 'Vendor')) {
    await vendor_profile.fetchProfile(auth.me.id);
  }
}

async function getAccountSettings({
  global,
  auth,
  brand_profile,
  developer_profile,
  consultant_profile,
  vendor_profile,
}) {
  isEmpty(global.countries) && global.fetchCountries();

  const id = auth.me.id;
  const role = auth.me.role;

  if (id) {
    if (is(role, 'Brand')) await brand_profile.fetchProfile(id);

    if (is(role, 'Developer')) {
      global.fetchBrands();
      await developer_profile.fetchProfile(id);
    }

    if (is(role, 'DesignAgency'))
      await consultant_profile.fetchProfileSettings(id);

    if (is(role, 'Vendor')) await vendor_profile.fetchProfileSettings(id);
  }
}

function partnerWithUs({ global }) {
  isEmpty(global.countries) && global.fetchCountries();
  isEmpty(global.categories) && global.fetchCategories();
}

function searchDesignAgencies({ global, search }) {
  search.fetchConsultants();

  isEmpty(global.categories) && global.fetchCategories();
  isEmpty(global.sub_categories) && global.fetchSubCategories();
  global.fetchBrands();
}

function getIndustryLeaders({ global }) {
  global.fetchIndustryLeaders();
}

function fetchBrandAndDevelopers({ global }) {
  global.fetchDevelopers();
  global.fetchBrands();
}

function fetchSubscriptions({ global }) {
  global.fetchStripePrices();
}

function getNotifications({ notifications }) {
  if (isEmpty(notifications.list)) notifications.fetchNotifications();
}

function getShortLists({ auth, brand_profile, developer_profile }) {
  const profile = is(auth.me.role, 'Brand') ? brand_profile : developer_profile;

  profile.getShortLists();
}

function getPinnedData({ global }) {
  if (isEmpty(global.pinned_logos)) global.fetchPinnedLogos();
  if (!global.pinned_industry_leaders) global.fetchPinnedIndustryLeaders();
}

export {
  getProfile,
  getOwnProfile,
  partnerWithUs,
  getAccountSettings,
  searchDesignAgencies,
  getIndustryLeaders,
  fetchBrandAndDevelopers,
  fetchSubscriptions,
  getNotifications,
  getShortLists,
  getPinnedData,
};
