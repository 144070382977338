import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import Types from 'prop-types';

import { ROUTES } from '../../constants';
import { is, stopPropagation } from '../../utils/global';
import DEFAULT_USERPIC_URL from '../../assets/icons/default-userpic.svg';

const UserDropdown = ({ auth, setIsLogin }) => {
  const containerRef = useRef(null);
  const [isOpen, setOpenDropdown] = useState(false);

  useEffect(() => {
    document.addEventListener('click', closeDropdownOnClickOutside);

    return () => {
      document.removeEventListener('click', closeDropdownOnClickOutside);
    };
  });

  function toggleOpenDropdown(event) {
    setOpenDropdown(!isOpen);
  }

  function closeDropdownOnClickOutside(event) {
    if (containerRef && containerRef.current) {
      if (!containerRef.current.contains(event.target)) {
        setOpenDropdown(false);
      }
    }
  }

  function logOut(event) {
    stopPropagation(event);
    setOpenDropdown(false);
    setIsLogin(false);
    auth.doLogout();
  }

  return (
    <div ref={containerRef} className="user-dropdown">
      <div className="user-name" onClick={toggleOpenDropdown}>
        {auth.me.name}
      </div>
      <button
        className={`btn icon-chevron-down${isOpen ? ' rotate' : ''}`}
        type="button"
        title="Open"
      />
      <ul className={`user-dropdown-menu${isOpen ? ' open' : ''}`}>
        <li>
          <img
            src={auth.me.logo ? auth.me.logo.url : DEFAULT_USERPIC_URL}
            alt={auth.me.name}
            className="account-logo"
          />

          <div className="user-name">{auth.me.name}</div>
          <div className="user-mail">{auth.me.email}</div>
          <div onClick={toggleOpenDropdown}>
            <Link to={ROUTES.settings} className="user-dropdown-link">
              Account Settings
            </Link>
          </div>
          {(is(auth.me.role, 'DesignAgency') || is(auth.me.role, 'Vendor')) && (
            <div onClick={toggleOpenDropdown}>
              <Link to={ROUTES.editProfile} className="user-dropdown-link">
                Edit Public Profile
              </Link>
            </div>
          )}
        </li>
        <li>
          <button type="button" className="btn-logout" onClick={logOut}>
            Log Out
          </button>
        </li>
      </ul>
    </div>
  );
};

UserDropdown.propTypes = {
  auth: Types.object.isRequired,
  setIsLogin: Types.func,
};

export default inject('auth')(observer(UserDropdown));
