import { types as t } from 'mobx-state-tree';

import { Brand } from '../models/Brand';

const BrandsDB = t
  .model('BrandsDB', {
    me: t.maybeNull(t.reference(Brand)),
    brandsList: t.optional(t.map(Brand), {}),
  })
  .actions((self) => ({
    setMe(me) {
      self.me = me.id;
    },

    put(brand: typeof Brand) {
      let newBrand = brand;
      const oldBrand = self.get(brand.id);

      if (oldBrand) {
        newBrand = Object.assign(oldBrand, newBrand);
      }
      return self.brandsList.put(newBrand);
    },

    get(id: number) {
      return self.brandsList.get(id.toString());
    },

    byIds(ids) {
      return ids.map(self.get);
    },
  }));

export { BrandsDB };
