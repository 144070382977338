import React, { useEffect } from 'react';
import { withRouter, matchPath } from 'react-router-dom';

import Routes from './routes/index';
import { ROUTES } from './constants';
import * as mountApi from './routes/routerApiMount';
import * as unmountApi from './routes/routerApiUnmount';

const route = (pathname, loader, exact = false) => [
  {
    path: pathname,
    exact,
  },
  loader,
];

const routes = [
  route(ROUTES.consultantSearch, mountApi.searchDesignAgencies),
  route(ROUTES.industryLeaders, mountApi.getIndustryLeaders),
  route(ROUTES.editProfile, mountApi.getOwnProfile),
  route(ROUTES.consultant, mountApi.getProfile),
  route(ROUTES.vendor, mountApi.getProfile),
  route(ROUTES.partnerWithUs, mountApi.partnerWithUs),
  route(ROUTES.settings, mountApi.getAccountSettings),
  route(ROUTES.brandsDevelopers, mountApi.fetchBrandAndDevelopers),
  route(ROUTES.subscription, mountApi.fetchSubscriptions),
  route(ROUTES.notifications, mountApi.getNotifications),
  route(ROUTES.shortLists, mountApi.getShortLists),
  route(ROUTES.shortList, mountApi.getShortLists),
  route(ROUTES.root, mountApi.getPinnedData),
];

const App = ({ history, stores }) => {
  history.listen(() => {
    window.scrollTo({ top: 0 });
    if (stores.modal.isOpen) {
      stores.modal.close();
    }
  });

  function mount() {
    for (const [config, load] of routes) {
      const match = matchPath(history.location.pathname, config);
      if (match) {
        load(stores, match);

        return () => {
          unmountApi[load.name] && unmountApi[load.name](stores, match);
        };
      }
    }
  }

  useEffect(mount, [history.location.key]); // eslint-disable-line

  return <Routes />;
};

export default withRouter(App);
