import React from 'react';
import Observer from 'react-intersection-observer';

const LazyLoading = ({ onChange }) => (
  <Observer onChange={onChange}>
    {({ ref }) => <div className="hidden-card" ref={ref} />}
  </Observer>
);

export default LazyLoading;
