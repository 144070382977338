import { types, flow, getEnv, getRoot } from 'mobx-state-tree';

import { is } from '../utils/global';

export const Pagination = types.optional(
  types.model('pagination', {
    current_page: types.optional(types.number, 0),
    per_page: types.optional(types.number, 10),
    total_records: types.optional(types.number, 0),
    total_pages: types.optional(types.number, 0),
  }),
  {}
);

const State = types.model('State', {
  state: types.frozen(),
  errors: types.frozen(),
});

const Global = types
  .model('Global', {
    countries: types.optional(types.frozen(), []),
    categories: types.optional(types.frozen(), []),
    sub_categories: types.optional(types.frozen(), []),
    brands: types.optional(types.frozen(), []),
    developers: types.optional(types.frozen(), []),
    vendors: types.optional(types.frozen(), []),
    responseState: types.optional(State, {}),
    subscriptions: types.optional(types.frozen(), []),
    industry_leaders: types.frozen(),
    pinned_industry_leaders: types.frozen(),
    pinned_logos: types.optional(types.frozen(), []),
    industryLeadersLoading: true,
    pinnedLeadersLoading: true,
    subscriptionsLoading: false,
    loading: false,
  })
  .actions((self) => ({
    fetchCountries: flow(function* () {
      const { http } = getEnv(self);

      const response = yield http.get('/countries');

      if (is(response.status, 200)) {
        const { countries } = yield response.json();
        self.countries = countries;
      }
    }),

    fetchCategories: flow(function* () {
      const { http } = getEnv(self);

      const response = yield http.get('/categories');
      if (is(response.status, 200)) {
        const { categories } = yield response.json();

        self.categories = categories;
      }
    }),

    fetchSubCategories: flow(function* () {
      const { http } = getEnv(self);

      const response = yield http.get('/sub_categories');
      if (response.ok) {
        const { sub_categories } = yield response.json();

        self.sub_categories = sub_categories;
      }
    }),

    fetchBrands: flow(function* () {
      const { http } = getEnv(self);
      const { auth } = getRoot(self);

      self.loading = true;

      const response = yield http.get('/brands');
      if (is(response.status, 200)) {
        const { brands } = yield response.json();

        self.brands = brands;
      }

      if (is(response.status, 401)) {
        auth.setUnauthorized();
      }
      self.loading = false;
    }),

    fetchDevelopers: flow(function* () {
      const { http } = getEnv(self);
      self.loading = true;

      const response = yield http.get('/developers');
      if (is(response.status, 200)) {
        const { developers } = yield response.json();

        self.developers = developers;
      }
      self.loading = false;
    }),

    fetchVendors: flow(function* () {
      const { http } = getEnv(self);

      const response = yield http.get('/vendors');
      if (is(response.status, 200)) {
        const { vendors } = yield response.json();

        self.vendors = vendors;
      }
    }),

    fetchStripePrices: flow(function* () {
      const { http } = getEnv(self);
      const { auth } = getRoot(self);

      self.subscriptionsLoading = true;

      const response = yield http.get('/stripe/products_and_prices');
      if (is(response.status, 200)) {
        const { products } = yield response.json();

        self.subscriptions = products;
      }

      if (is(response.status, 401)) {
        auth.setUnauthorized();
      }
      self.subscriptionsLoading = false;
    }),

    sendInvitation: flow(function* (type, email) {
      const { http } = getEnv(self);
      const { auth, modal, snackBars } = getRoot(self);

      const response = yield http.post(
        `/invitations/for_${type}?email=${email}`
      );
      if (is(response.status, 200)) {
        const { message } = yield response.json();

        snackBars.addNotification({
          message: message,
          options: {
            variant: 'success',
          },
        });

        modal.close();
      }

      if (is(response.status, 401)) {
        auth.setUnauthorized();
      }

      if (is(response.status, 422)) {
        const { error } = yield response.json();

        snackBars.addNotification({
          message: error,
          options: {
            variant: 'error',
          },
        });
      }
    }),

    fetchIndustryLeaders: flow(function* () {
      const { http } = getEnv(self);
      const { auth } = getRoot(self);

      self.industryLeadersLoading = true;

      const response = yield http.get('/industry_leaders');

      if (is(response.status, 200)) {
        const { industry_leaders } = yield response.json();

        self.industry_leaders = industry_leaders;
      }

      if (is(response.status, 401)) {
        auth.setUnauthorized();
      }

      self.industryLeadersLoading = false;
    }),

    fetchPinnedIndustryLeaders: flow(function* () {
      const { http } = getEnv(self);

      self.pinnedLeadersLoading = true;

      const response = yield http.get('/first_industry_leaders');

      if (is(response.status, 200)) {
        const { first_industry_leaders } = yield response.json();
        self.pinned_industry_leaders = first_industry_leaders;
      }
      self.pinnedLeadersLoading = false;
    }),

    fetchPinnedLogos: flow(function* () {
      const { http } = getEnv(self);

      const response = yield http.get('/pinned_logos');

      if (is(response.status, 200)) {
        const { logos } = yield response.json();
        self.pinned_logos = logos;
      }
    }),
  }));

export { Global };
