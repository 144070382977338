import { types } from 'mobx-state-tree';

const SnackBars = types
  .model('SnackBars', {
    notifications: types.optional(
      types.array(
        types.model('newMessage', {
          key: types.frozen(),
          preventDuplicate: true,
          message: types.optional(types.string, ''),
          options: types.optional(
            types.model('styles', {
              variant: types.optional(types.string, ''),
            }),
            {}
          ),
        })
      ),
      []
    ),
  })
  .actions((self) => ({
    addNotification(note) {
      self.notifications.push({
        key: new Date().getTime() + Math.random(),
        preventDuplicate: true,
        ...note,
      });
    },
    removeSnackbar(note) {
      self.notifications.remove(note);
    },
  }));

export { SnackBars };
