import { Component } from 'react';

import Footer from './Footer';
import Header from './header/index';
import Modal from './modal';

class Layout extends Component {
  render() {
    const { children } = this.props;

    return (
      <>
        <Header />
        <div className="page-content">{children}</div>
        <Footer />
        <Modal />
      </>
    );
  }
}

export default Layout;
