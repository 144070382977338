import { types, flow, getEnv, getRoot } from 'mobx-state-tree';

import { is } from '../utils/global';

const ContactUs = types
  .model('ContactUs', {
    state: types.frozen(),
    errors: types.frozen(),
  })
  .actions((self) => ({
    sendMessage: flow(function* (body, onSuccess) {
      const { http } = getEnv(self);
      const { modal } = getRoot(self);

      self.state = 'pending';

      const response = yield http.post('/contact_us_requests', body);

      if (is(response.status, 200) || is(response.status, 201)) {
        self.state = 'success';
        onSuccess();
        modal.open('EMAIL_SENT', {});
      }

      if (is(response.status, 422)) {
        const { error } = yield response.json();
        self.state = 'error';
        self.errors = error;
      }
    }),
  }));

export { ContactUs };
