import { types } from "mobx-state-tree";

import { hideScroll } from '../utils/global';

const Modal = types
  .model("Modal", {
    isOpen: false,
    type: types.optional(types.string, ""),
    options: types.frozen()
  })
  .actions(self => ({
    open(type, options) {
      
      self.isOpen = true;
      self.type = type;
      self.options = options;

      hideScroll();
    },

    close() {
      self.isOpen = false;
      self.type = "";

      hideScroll();
    }
  }));

export { Modal };
