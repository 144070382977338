import React from 'react';
import { NavLink, Link, withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import Types from 'prop-types';

import { ROUTES } from '../../constants';
import CategoryDropdown from '../../components/common/CategoryDropdown';
import { is, stopPropagation } from '../../utils/global';
import NotificationsDropdown from './notificationsDropdown';
import DEFAULT_USERPIC_URL from '../../assets/icons/default-userpic.svg';

const MobileNavigation = ({
  auth,
  location,
  openModal,
  toggleOpenMenu,
  onScrollToTop,
  isOpenMenu,
  setOpenMenu,
  setIsLogin,
  isLogin,
}) => {
  function onCloseMenu(event) {
    if (isOpenMenu) {
      setOpenMenu(false);
    }
  }

  function onLogout(event) {
    stopPropagation(event);
    setOpenMenu(false);
    setIsLogin(false);
    auth.doLogout();
  }

  const isSearchPage = location.pathname.includes(`/consultants/search`);

  return (
    <>
      <div className={`header__nav-wrapper${isOpenMenu ? ' menu-open' : ''}`}>
        <nav onClick={onCloseMenu}>
          <ul className="header__nav">
            {auth.isLogin && (
              <li>
                <div className="profile-info">
                  <img
                    src={auth.me.logo ? auth.me.logo.url : DEFAULT_USERPIC_URL}
                    alt={auth.me.name}
                    className="profile-info__img"
                  />

                  <div className="profile-info__name">
                    <div className="name">{auth.me.name}</div>
                    <div className="email">{auth.me.email}</div>
                  </div>
                </div>
              </li>
            )}
            {auth.isLogin ? (
              <>
                <li>
                  <NavLink to={ROUTES.industryLeaders}>
                    Industry Leaders
                  </NavLink>
                </li>
                <li>
                  <NavLink to={ROUTES.brandsDevelopers}>
                    Brands & Developers
                  </NavLink>
                </li>
                {(is(auth.me.role, 'Brand') ||
                  is(auth.me.role, 'Developer')) && (
                  <li
                    className={`tab${isSearchPage ? ' active' : ''}`}
                    onClick={stopPropagation}>
                    <CategoryDropdown
                      type={'header'}
                      onCloseMenu={onCloseMenu}
                    />
                  </li>
                )}
              </>
            ) : (
              <>
                <li onClick={openModal}>
                  <span>Industry Leaders</span>
                </li>
                <li onClick={openModal}>
                  <span> Brands & Developers</span>
                </li>
                <li onClick={openModal}>
                  <span>Categories</span>
                </li>
              </>
            )}
            <li>
              <NavLink to={ROUTES.confidentiality}>Confidentiality</NavLink>
            </li>
            <li>
              <NavLink to={ROUTES.howItWorks}>How It Works</NavLink>
            </li>
            {/* Button LOGOUT and link to Account Settings */}
            {isLogin ? (
              <>
                <li>
                  <NavLink to={ROUTES.settings}>Account Settings</NavLink>
                </li>
                {(is(auth.me.role, 'DesignAgency') ||
                  is(auth.me.role, 'Vendor')) && (
                  <li>
                    <NavLink to={ROUTES.editProfile}>
                      Edit Public Profile
                    </NavLink>
                  </li>
                )}
                <button type="button" className="btn-logout" onClick={onLogout}>
                  Log out
                </button>
              </>
            ) : (
              <div className="header__login" onClick={onCloseMenu}>
                <NavLink className="login" to={ROUTES.login}>
                  Log in
                </NavLink>
                <NavLink
                  className="lnk-as-btn-primary"
                  to={ROUTES.partnerWithUs}>
                  Partner With Us
                </NavLink>
              </div>
            )}
          </ul>
        </nav>
      </div>
      {auth.isLogin &&
        (is(auth.me.role, 'Brand') || is(auth.me.role, 'Developer')) && (
          <Link className="icon-icon-bookmark" to={ROUTES.shortLists}></Link>
        )}
      {auth.isLogin && <NotificationsDropdown me={auth.me} />}
      <button
        className={`btn-menu${isOpenMenu ? ' menu-open' : ''}`}
        onClick={toggleOpenMenu}>
        <span>toggle menu</span>
      </button>
    </>
  );
};

MobileNavigation.propTypes = {
  auth: Types.object.isRequired,
  toggleOpenMenu: Types.func.isRequired,
  isOpenMenu: Types.bool.isRequired,
  setOpenMenu: Types.func.isRequired,
  openModal: Types.func,
  onScrollToTop: Types.func,
  setIsLogin: Types.func,
  isLogin: Types.bool,
};

export default inject('auth')(withRouter(observer(MobileNavigation)));
