import { not, isEmpty } from 'ramda';

const { is } = Object;

function stopPropagation(event) {
  event.stopPropagation();
}

const notIs = (value1, value2) => not(is(value1, value2));

function emptyArray(itemsCount: number) {
  const list = [];
  let i = 0;

  while (notIs(i, itemsCount)) {
    list.push(i);
    i++;
  }

  return list;
}

const pluralizeString = (string, amount) =>
  is(amount, 1) ? string : `${string}s`;

function hideScroll() {
  if (document.body) document.body.classList.toggle('overflow-hidden');
}

function buildFormData(formData, data, parentKey) {
  if (
    data &&
    typeof data === 'object' &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    Object.keys(data).forEach((key) => {
      buildFormData(
        formData,
        data[key],
        parentKey ? `${parentKey}[${key}]` : key
      );
    });
  } else {
    const value = data == null ? '' : data;
    formData.append(parentKey, value);
  }
}

function jsonToFormData(data) {
  const formData = new FormData();

  buildFormData(formData, data);

  return formData;
}

function debounce(func, delay = 300) {
  let inDebounce;

  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(inDebounce);
    inDebounce = setTimeout(() => func.apply(context, args), delay);
  };
}

function makeQueryFromParams(params) {
  return Object.keys(params)
    .map((key) => {
      if (Array.isArray(params[key])) {
        return params[key]
          .map(
            (value) => encodeURIComponent(key) + '=' + encodeURIComponent(value)
          )
          .join('&');
      }
      return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
    })
    .join('&');
}

function scrollToInvalidError() {
  const elements = document.getElementsByClassName('error');
  elements[0].scrollIntoView({ block: 'center', behavior: 'smooth' });
}

const createController = () => new AbortController();

function dateTime(dateTime) {
  const now = new Date().getTime();
  const created = new Date(dateTime).getTime();
  const diff = Math.abs(now - created);

  const minutes = Math.ceil((diff / (1000 * 60)).toFixed(1));
  const hours = Math.floor((diff / (1000 * 60 * 60)).toFixed(1));
  const days = Math.floor((diff / (1000 * 60 * 60 * 24)).toFixed(1));

  if (minutes < 1) return 'Now';
  if (hours < 1) return `${minutes} ${pluralizeString('minute', minutes)} ago`;
  if (hours >= 1 && days < 1) return getDateMinutesAndHours(dateTime);

  return dateToString(dateTime);
}

function getDateMinutesAndHours(dateTime) {
  var date = new Date(dateTime);
  var options = { hour: 'numeric', minute: 'numeric' };

  return new Intl.DateTimeFormat('en-US', options).format(date);
}

const month = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

function dateToString(dateNumber) {
  const date = new Date(dateNumber);
  const year = date.getFullYear();
  const monthKey = date.getMonth();
  const day = date.getDate();

  return `${month[monthKey]} ${day}, ${year}`;
}

function getAppliedFilters(params, categories, brands, setAppliedFilters) {
  let filters = [];

  // By categories
  params.category_ids.forEach((id) => {
    const existingCategory = categories.find((category) => is(id, category.id));

    if (existingCategory) {
      filters.push(existingCategory.name);
    }
  });

  // By experience
  const experience = `${params.min_experience} - ${
    params.max_experience >= 10 ? '10+' : params.max_experience
  } years of experience`;

  filters.push(experience);

  // Worked with brands
  let preApprovalBrands = [];

  params.brand_ids.forEach((id) => {
    const existingBrand = brands.find((brand) => is(id, brand.id));

    if (existingBrand) {
      preApprovalBrands.push(existingBrand.name);
    }
  });

  if (!isEmpty(preApprovalBrands)) {
    const stringOfPreApprovalBrands = `Worked with ${preApprovalBrands.join(
      ', '
    )}`;
    filters.push(stringOfPreApprovalBrands);
  }

  return setAppliedFilters(filters);
}

function loadClipboard() {
  'clipboard' in navigator ||
    import(
      /* webpackChunkName: 'clipboard-polyfill' */ 'clipboard-polyfill'
    ).then((module) => (navigator._clipboard = module.default));
}

export {
  is,
  notIs,
  stopPropagation,
  emptyArray,
  pluralizeString,
  hideScroll,
  jsonToFormData,
  debounce,
  makeQueryFromParams,
  scrollToInvalidError,
  createController,
  dateTime,
  dateToString,
  getDateMinutesAndHours,
  getAppliedFilters,
  loadClipboard,
};
