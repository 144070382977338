import { types as t, flow, getEnv, getRoot } from 'mobx-state-tree';

import { is } from '../utils/global';

const Consultant = t
  .model('Consultant', {
    avg_rating: 0,
    awards: t.optional(t.array(t.frozen()), []),
    brand_approvals: t.optional(t.array(t.frozen()), []),
    category: t.frozen(),
    company_email: t.maybeNull(t.string),
    contact_person: t.maybeNull(t.string),
    corporate_website_url: t.maybeNull(t.string),
    covered_photo: t.maybeNull(t.frozen()),
    description: t.maybeNull(t.string),
    email: t.maybeNull(t.string),
    employees: t.maybeNull(t.number),
    id: t.identifierNumber,
    included_in_some_favourite_list: t.maybeNull(t.boolean, false),
    key_partners: t.optional(t.array(t.frozen()), []),
    logo: t.maybeNull(t.frozen()),
    name: t.maybeNull(t.string),
    offices: t.optional(t.array(t.frozen()), []),
    phone_number: t.maybeNull(t.string),
    portfolio: t.frozen(),
    project_photos: t.optional(t.array(t.frozen()), []),
    projects: t.optional(t.array(t.frozen()), []),
    sub_category: t.frozen(),
    years_of_experience: t.maybeNull(t.number),
    preferred_vendors: t.maybeNull(t.array(t.frozen()), []),
    partners: t.maybeNull(t.array(t.frozen()), []),
    reviews: t.maybeNull(t.array(t.frozen()), []),
    loading: false,
    role: 'DesignAgency',
  })
  .actions((self) => ({
    addOffice: flow(function* (data) {
      const { http } = getEnv(self);
      const { modal, auth } = getRoot(self);

      const body = { office: data };

      const response = yield http.post('/offices', body);

      if (is(response.status, 201)) {
        const { office } = yield response.json();

        self.offices.push(office);
        modal.close();
      }

      if (is(response.status, 401)) {
        auth.setUnauthorized();
      }
    }),

    editOffice: flow(function* (data, officeId) {
      const { http } = getEnv(self);
      const { modal, auth } = getRoot(self);

      const body = { office: data };

      const response = yield http.put(`/offices/${officeId}`, body);

      if (is(response.status, 200)) {
        const { office } = yield response.json();

        const existingOffice = self.offices.find(({ id }) => id === office.id);
        if (existingOffice) {
          const index = self.offices.indexOf(existingOffice);
          if (index !== -1) {
            self.offices[index] = office;
          }
        }
        if (modal.isOpen) modal.close();
      }

      if (is(response.status, 401)) {
        auth.setUnauthorized();
      }
    }),

    deleteOffice: flow(function* (officeId) {
      const { http } = getEnv(self);
      const { auth } = getRoot(self);

      const response = yield http.delete(`/offices/${officeId}`);

      if (is(response.status, 204)) {
        self.offices = self.offices.filter(({ id }) => id !== officeId);
      }

      if (is(response.status, 401)) {
        auth.setUnauthorized();
      }
    }),

    addHeadOffice: flow(function* (data) {
      const { http } = getEnv(self);
      const { auth } = getRoot(self);

      const body = { office: data };

      const existingHeadOffice = self.offices.find(
        ({ head_office }) => head_office === true
      );

      if (existingHeadOffice) {
        self.editOffice(data, existingHeadOffice.id);
      } else {
        const response = yield http.post('/offices', body);

        if (is(response.status, 201)) {
          const { office } = yield response.json();
          self.offices.push(office);
        }

        if (is(response.status, 401)) {
          auth.setUnauthorized();
        }
      }
    }),

    addAward: flow(function* (data) {
      const { http } = getEnv(self);
      const { modal, auth } = getRoot(self);

      const body = { award: data };

      const response = yield http.post('/awards', body);

      if (is(response.status, 201)) {
        const { award } = yield response.json();

        self.awards.push(award);
        modal.close();
      }

      if (is(response.status, 401)) {
        auth.setUnauthorized();
      }
    }),

    editAward: flow(function* (data, awardId) {
      const { http } = getEnv(self);
      const { modal, auth } = getRoot(self);

      const body = { award: data };

      const response = yield http.put(`/awards/${awardId}`, body);

      if (is(response.status, 200)) {
        const { award } = yield response.json();

        const existingAward = self.awards.find(({ id }) => id === award.id);
        if (existingAward) {
          const index = self.awards.indexOf(existingAward);
          if (index !== -1) {
            self.awards[index] = award;
          }
        }
        modal.close();
      }

      if (is(response.status, 401)) {
        auth.setUnauthorized();
      }
    }),

    deleteAward: flow(function* (awardId) {
      const { http } = getEnv(self);
      const { auth } = getRoot(self);

      const response = yield http.delete(`/awards/${awardId}`);

      if (is(response.status, 204)) {
        self.awards = self.awards.filter(({ id }) => id !== awardId);
      }

      if (is(response.status, 401)) {
        auth.setUnauthorized();
      }
    }),

    addProject: flow(function* (data, id, onSuccess) {
      const { http } = getEnv(self);
      const { modal, auth } = getRoot(self);

      const body = { project: data };

      const response = yield http.post('/projects', body);

      if (is(response.status, 201)) {
        const { project } = yield response.json();

        self.projects.push(project);

        if (onSuccess) onSuccess();
        modal.close();
      }

      if (is(response.status, 401)) {
        auth.setUnauthorized();
      }
    }),

    editProject: flow(function* (data, projectId, onSuccess) {
      const { http } = getEnv(self);
      const { modal, auth } = getRoot(self);

      const body = { project: data };

      const response = yield http.put(`/projects/${projectId}`, body);

      if (is(response.status, 200)) {
        const { project } = yield response.json();

        const existingProject = self.projects.find(
          ({ id }) => id === project.id
        );
        if (existingProject) {
          const index = self.projects.indexOf(existingProject);
          if (index !== -1) {
            self.projects[index] = project;
          }
        }

        if (onSuccess) onSuccess();
        modal.close();
      }

      if (is(response.status, 401)) {
        auth.setUnauthorized();
      }
    }),

    deleteProject: flow(function* (projectId) {
      const { http } = getEnv(self);
      const { auth } = getRoot(self);

      const response = yield http.delete(`/projects/${projectId}`);

      if (is(response.status, 204)) {
        self.projects = self.projects.filter(({ id }) => id !== projectId);
      }

      if (is(response.status, 401)) {
        auth.setUnauthorized();
      }
    }),

    addKeyPartner: flow(function* (data) {
      const { http } = getEnv(self);
      const { modal, auth } = getRoot(self);

      self.loading = true;
      const body = { key_partner: data };

      const response = yield http.post('/key_partners', body);

      if (is(response.status, 201)) {
        const { key_partner } = yield response.json();

        self.key_partners.push(key_partner);
        modal.close();
      }

      if (is(response.status, 401)) {
        auth.setUnauthorized();
      }

      self.loading = false;
    }),

    editKeyPartner: flow(function* (data, partnerId) {
      const { http } = getEnv(self);
      const { modal, auth } = getRoot(self);

      self.loading = true;
      const body = { key_partner: data };

      const response = yield http.put(`/key_partners/${partnerId}`, body);

      if (is(response.status, 200)) {
        const { key_partner } = yield response.json();

        const existingKeyPartner = self.key_partners.find(
          ({ id }) => id === key_partner.id
        );
        if (existingKeyPartner) {
          const index = self.key_partners.indexOf(existingKeyPartner);
          if (index !== -1) {
            self.key_partners[index] = key_partner;
          }
        }
        modal.close();
      }

      if (is(response.status, 401)) {
        auth.setUnauthorized();
      }

      self.loading = false;
    }),

    deleteKeyPartner: flow(function* (partnerId) {
      const { http } = getEnv(self);
      const { auth } = getRoot(self);

      const response = yield http.delete(`/key_partners/${partnerId}`);

      if (is(response.status, 204)) {
        self.key_partners = self.key_partners.filter(
          ({ id }) => id !== partnerId
        );
      }

      if (is(response.status, 401)) {
        auth.setUnauthorized();
      }
    }),

    deleteAttachment: flow(function* (id, type) {
      const { http } = getEnv(self);
      const { auth } = getRoot(self);

      const response = yield http.delete(`/attachments/${id}`);

      if (is(response.status, 204)) {
        if (is(type, 'portfolio')) self.portfolio = null;
        if (is(type, 'logo')) {
          self.logo = null;
          auth.getSession();
        }
        if (is(type, 'cover')) self.covered_photo = null;
      }

      if (is(response.status, 401)) {
        auth.setUnauthorized();
      }
    }),

    addProjectPhoto: flow(function* (data, id, onSuccess) {
      const { http } = getEnv(self);
      const { modal, auth } = getRoot(self);

      self.loading = true;
      const response = yield http.post('/project_photos', data);

      if (is(response.status, 201)) {
        const { project_photo } = yield response.json();

        self.project_photos.push(project_photo);
        onSuccess(project_photo);
        modal.close();
      }

      if (is(response.status, 401)) {
        auth.setUnauthorized();
      }
      self.loading = false;
    }),

    editProjectPhoto: flow(function* (data, id, onSuccess) {
      const { http } = getEnv(self);
      const { modal, auth } = getRoot(self);

      self.loading = true;
      const response = yield http.put(`/project_photos/${id}`, data);

      if (is(response.status, 200)) {
        const { project_photo } = yield response.json();

        const existingProjectPhoto = self.project_photos.find(
          ({ id }) => id === project_photo.id
        );
        if (existingProjectPhoto) {
          const index = self.project_photos.indexOf(existingProjectPhoto);
          if (index !== -1) {
            self.project_photos[index] = project_photo;
          }
        }
        onSuccess(project_photo);
        modal.close();
      }

      if (is(response.status, 401)) {
        auth.setUnauthorized();
      }

      self.loading = false;
    }),

    deleteProjectPhoto: flow(function* (photoId) {
      const { http } = getEnv(self);
      const { auth } = getRoot(self);

      const response = yield http.delete(`/project_photos/${photoId}`);

      if (is(response.status, 204)) {
        self.project_photos = self.project_photos.filter(
          ({ id }) => id !== photoId
        );
      }

      if (is(response.status, 401)) {
        auth.setUnauthorized();
      }
    }),

    updateEmail: flow(function* (data) {
      const { http } = getEnv(self);
      const { snackBars, auth } = getRoot(self);

      self.loading = true;

      const body = {
        design_agency: data,
      };

      const response = yield http.patch(
        `/design_agencies/${self.id}/update_email`,
        body
      );

      if (is(response.status, 200)) {
        const { message } = yield response.json();

        snackBars.addNotification({
          message: message,
          options: {
            variant: 'success',
          },
        });

        self.email = data.email;

        auth.getSession();
      }

      if (is(response.status, 401)) {
        auth.setUnauthorized();
      }

      if (is(response.status, 422)) {
        const { error } = yield response.json();

        snackBars.addNotification({
          message: error,
          options: {
            variant: 'error',
          },
        });
      }
      self.loading = false;
    }),

    addPreApprovalBrand: flow(function* (body) {
      const { http } = getEnv(self);
      const { auth } = getRoot(self);

      const response = yield http.post('/brand_approvals', body);
      if (is(response.status, 200)) {
        const { brand_approval } = yield response.json();
        self.brand_approvals.push(brand_approval);
      }

      if (is(response.status, 401)) {
        auth.setUnauthorized();
      }
    }),

    deletePreApprovalBrand: flow(function* (brandId) {
      const { http } = getEnv(self);
      const { auth } = getRoot(self);

      const response = yield http.delete(`/brand_approvals/${brandId}`);
      if (is(response.status, 200)) {
      }

      if (is(response.status, 401)) {
        auth.setUnauthorized();
      }
    }),

    addPreferredVendors: flow(function* (data) {
      const { http } = getEnv(self);
      const { auth, snackBars } = getRoot(self);

      const body = {
        preferred_vendor: {
          vendor_id: data,
        },
      };

      const response = yield http.post(`/preferred_vendors`, body);

      if (is(response.status, 201)) {
        // TODO: change updating
      }

      if (is(response.status, 401)) {
        auth.setUnauthorized();
      }

      if (is(response.status, 422)) {
        const { error } = yield response.json();

        snackBars.addNotification({
          message: error,
          options: {
            variant: 'error',
          },
        });
      }
    }),

    deletePreferredVendors: flow(function* (vendorId) {
      const { http } = getEnv(self);
      const { auth, snackBars } = getRoot(self);

      const response = yield http.delete(`/preferred_vendors/${vendorId}`);

      if (is(response.status, 204)) {
        // TODO: change updating
      }

      if (is(response.status, 401)) {
        auth.setUnauthorized();
      }

      if (is(response.status, 422)) {
        const { error } = yield response.json();

        snackBars.addNotification({
          message: error,
          options: {
            variant: 'error',
          },
        });
      }
    }),

    addPreferredPartners: flow(function* (partnerId) {
      const { http } = getEnv(self);
      const { auth, snackBars } = getRoot(self);

      const body = { design_agency_partnership: { partner_id: partnerId } };

      const response = yield http.post(`/design_agency_partners`, body);

      if (is(response.status, 201)) {
        // TODO: create updating
      }

      if (is(response.status, 401)) {
        auth.setUnauthorized();
      }

      if (is(response.status, 422)) {
        const { error } = yield response.json();

        snackBars.addNotification({
          message: error,
          options: {
            variant: 'error',
          },
        });
      }
    }),

    deletePreferredPartners: flow(function* (partnerId) {
      const { http } = getEnv(self);
      const { auth, snackBars } = getRoot(self);

      const response = yield http.delete(
        `/design_agency_partners/${partnerId}`
      );

      if (is(response.status, 204)) {
        // TODO: create updating
      }

      if (is(response.status, 401)) {
        auth.setUnauthorized();
      }

      if (is(response.status, 422)) {
        const { error } = yield response.json();

        snackBars.addNotification({
          message: error,
          options: {
            variant: 'error',
          },
        });
      }
    }),
  }))
  .views((self) => ({}));

export { Consultant };
