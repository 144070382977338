import { types, flow, getEnv, getRoot } from 'mobx-state-tree';

import { is } from '../utils/global';
import { Consultant } from '../models/Consultant';

const ConsultantProfile = types
  .model('ConsultantProfile', {
    loading: true,
    isImageUpdating: false,
    user: types.maybeNull(types.reference(Consultant)),
    stripe_details: types.optional(types.maybeNull(types.frozen())),
    state: types.frozen(),
    errors: types.frozen(),
  })
  .actions((self) => ({
    fetchProfile: flow(function* (profileId) {
      const { http } = getEnv(self);
      const { auth } = getRoot(self);

      self.state = 'pending';
      self.loading = true;

      const response = yield http.get(`/design_agencies/${profileId}`);

      if (is(response.status, 200)) {
        const { design_agency } = yield response.json();
        self.putProfileData(design_agency);
        self.state = 'success';
      }

      if (is(response.status, 404)) {
        const { error } = yield response.json();

        self.errors = error;
        self.state = 'not found';
      }

      if (is(response.status, 401)) {
        auth.setUnauthorized();
      }
      self.loading = false;
    }),

    fetchProfileSettings: flow(function* (profileId) {
      const { http } = getEnv(self);
      const { auth } = getRoot(self);

      self.loading = true;
      self.state = 'pending';

      const response = yield http.get(`/design_agencies/${profileId}/settings`);

      if (is(response.status, 200)) {
        const { design_agency } = yield response.json();

        self.stripe_details = design_agency.stripe_details;
        self.fetchProfile(profileId);
      }

      if (is(response.status, 404)) {
        const { error } = yield response.json();

        self.errors = error;
        self.state = 'not found';
        self.loading = false;
      }

      if (is(response.status, 401)) {
        auth.setUnauthorized();
        self.state = '';
        self.loading = false;
      }
    }),

    updateProfile: flow(function* (data, onSuccess) {
      const { http } = getEnv(self);
      const { snackBars, auth } = getRoot(self);

      self.loading = true;
      self.state = 'pending';
      self.errors = '';
      const profileId = self.user.id;

      const body = {
        design_agency: data,
      };

      const response = yield http.put(`/design_agencies/${profileId}`, body);

      if (is(response.status, 200)) {
        const { design_agency } = yield response.json();

        self.putProfileData(design_agency);
        auth.getSession();
        self.state = 'success';

        if (onSuccess) {
          onSuccess();
        } else {
          snackBars.addNotification({
            message: 'Successfully updated',
            options: {
              variant: 'success',
            },
          });
        }
      }

      if (is(response.status, 401)) {
        self.state = '';
        auth.setUnauthorized();
      }

      if (is(response.status, 404)) {
        const { error } = yield response.json();

        self.errors = error;
        self.state = 'not found';
      }

      if (is(response.status, 422)) {
        const { error } = yield response.json();
        self.state = 'error';
        self.errors = error;

        snackBars.addNotification({
          message: self.errors,
          options: {
            variant: 'error',
          },
        });
      }

      self.loading = false;
    }),

    putProfileData(data) {
      const { consultants_db } = getRoot(self);
      self.user = data.id;
      self.state = 'success';
      self.loading = false;
      self.clearErrors();

      return consultants_db.put(data);
    },

    deleteProfile: flow(function* (profileId) {
      const { http } = getEnv(self);
      const { auth } = getRoot(self);

      const response = yield http.delete(`/design_agencies/${profileId}`);

      if (is(response.status, 204)) {
        localStorage.removeItem('token');
        auth.setUnauthorized();
      }
    }),

    clearErrors() {
      self.errors = '';
    },

    createProfileView: flow(function* (data) {
      const { http } = getEnv(self);
      const { auth } = getRoot(self);

      const body = {
        profile_view: data,
      };
      const response = yield http.post(`/profile_views`, body);

      if (is(response.status, 201)) {
        auth.getSession();
      }

      if (is(response.status, 401)) {
        auth.setUnauthorized();
      }
    }),
  }));

export { ConsultantProfile };
