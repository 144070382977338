import { types } from 'mobx-state-tree';

import * as Stores from './';

const RootStore = types.model('RootStore', {
  auth: types.optional(Stores.Auth, {}),
  modal: types.optional(Stores.Modal, {}),
  contact_us: types.optional(Stores.ContactUs, {}),
  consultant_profile: types.optional(Stores.ConsultantProfile, {}),
  consultants_db: types.optional(Stores.ConsultantsDB, {}),
  brand_profile: types.optional(Stores.BrandProfile, {}),
  brands_db: types.optional(Stores.BrandsDB, {}),
  developer_profile: types.optional(Stores.DeveloperProfile, {}),
  developers_db: types.optional(Stores.DevelopersDB, {}),
  vendor_profile: types.optional(Stores.VendorProfile, {}),
  vendors_db: types.optional(Stores.VendorsDB, {}),

  // Global
  global: types.optional(Stores.Global, {}),

  snackBars: types.optional(Stores.SnackBars, {}),
  search: types.optional(Stores.Search, {}),
  notifications: types.optional(Stores.Notifications, {}),
  lists_db: types.optional(Stores.ListsDB, {}),
});

function createStores({ snapshot, ...deps }): IRootStoreType {
  return RootStore.create(snapshot, deps);
}

export { createStores };
